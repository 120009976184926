import API from '../..';
const ENTITY = 'companies-catalogs';

export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, { ...data });
export const deleteOption = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const fetchListCatalogs = (type) => API.get(`/api/v1/${ENTITY}/${type}`);
export const getOption = (id) => API.get(`/api/v1/${ENTITY}/${id}`);
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
export const updateOrderItems = (values) =>
    API.put(`/api/v1/${ENTITY}/update-order/`, { ...values });
