import API from '../..';
const ENTITY = 'generation_profile';

export const createItem = (data) =>
    API.post(`/api/v2/${ENTITY}/`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
export const deleteItem = (id) => API.delete(`/api/v2/${ENTITY}/?id=${id}`);
export const getItem = (political_division1) =>
    API.get(`/api/v2/${ENTITY}/`, {
        params: { political_division1: political_division1 },
    });
export const getItems = (political_division1) =>
    API.get(`/api/v2/${ENTITY}_list/`, {
        params: { political_division1: political_division1 },
    });
