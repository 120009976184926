import API from '../..';
const ENTITY = 'datasheets';

export const deleteDatasheet = (id) =>
    API.delete(`/api/v1/media/${ENTITY}/${id}/`);
export const downloadDatasheet = (name) =>
    API.get(`/api/v1/pdf/${ENTITY}/${name}`, { responseType: 'blob' });
export const getProductDatasheets = (productKey, productId) =>
    API.get(`/api/v1/${ENTITY}/${productKey}/${productId}/`);
export const updateDatasheet = (data, id) =>
    API.put(`/api/v1/media/${ENTITY}/${id}/`, { ...data });
export const uploadDatasheet = (data, productKey, productId) =>
    API.post(`/api/v1/${ENTITY}/${productKey}/${productId}/`, { ...data });
