import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'sunwise-ui';

import { ReactHookFormSwitch } from 'common/components/form/bootstrap';

const LocationToggle = ({ control, disabled }) => {
    const { t } = useTranslation();

    return (
        <Grid container>
            <Grid item xs={2}>
                <ReactHookFormSwitch
                    control={control}
                    disabled={disabled}
                    name="half_mile_installation"
                />
            </Grid>
            <Grid item xs>
                <Typography variant="caption">
                    {t(
                        'The installed equipment is located more than 500 meters from any coastal area. Those less than 500 meters they do not apply for this policy',
                    )}
                </Typography>
            </Grid>
        </Grid>
    );
};

LocationToggle.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
};
export default LocationToggle;
