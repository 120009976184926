import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Tooltip } from 'sunwise-ui';

import { getCountryCurrencySymbol } from 'common/utils/helpers/session';

const FieldsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
`;

const InfoContainer = styled(Box)`
    align-content: start;
    display: flex;
    flex-direction: column;
    margin: 5px 0;
`;

const TagName = styled(Box)`
    color: #202253;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
`;
const PriceUnit = styled(Box)`
    font-size: 12px;
    line-height: 20px;
`;

const RowContainer = styled(Grid)`
    background: #fff;
    border-radius: 8px;
    display: flex;
    padding: 10px 0;
    position: relative;
`;

const ActionContainer = styled(Box)`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
`;

const AddButtonContainer = styled(Box)`
    align-items: center;
    display: flex;
    justify-content: end;
`;

const FieldArrayNonDefaultConcepts = ({
    changeInput,
    disabled,
    fields,
    values,
}) => {
    const { t } = useTranslation();

    const countryCurrencySymbol = getCountryCurrencySymbol();

    return (
        <FieldsContainer>
            {fields.map((field, index) => {
                const typeValue =
                    values[index].unit &&
                    values[index].unit !== '' &&
                    values[index].unit.search('%') >= 0
                        ? '%'
                        : countryCurrencySymbol;

                const formattedPrice =
                    typeValue === '%'
                        ? `${values[index].price}${typeValue}`
                        : `${typeValue}${values[index].price}`;

                return !values[index].is_default ? (
                    <RowContainer container key={`Grid-${field}`}>
                        <Grid item xs>
                            <InfoContainer>
                                <TagName>{values[index].tag_name}</TagName>
                                <PriceUnit>{`${formattedPrice} (${values[
                                    index
                                ]?.unit
                                    ?.toString()
                                    .replace(
                                        '$',
                                        countryCurrencySymbol,
                                    )})`}</PriceUnit>
                            </InfoContainer>
                        </Grid>
                        <Grid item xs>
                            <ActionContainer disabled={disabled}>
                                <AddButtonContainer>
                                    <Tooltip title={t('Add')}>
                                        <div
                                            className={`add-button-${index}-${values[index]?.id}`}
                                        >
                                            <IconButton
                                                disabled={disabled}
                                                onClick={() =>
                                                    changeInput(
                                                        `${field}.is_default`,
                                                        !values[index]
                                                            .is_default,
                                                    )
                                                }
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                </AddButtonContainer>
                            </ActionContainer>
                        </Grid>
                    </RowContainer>
                ) : null;
            })}
        </FieldsContainer>
    );
};

FieldArrayNonDefaultConcepts.propTypes = {
    changeInput: PropTypes.func,
    disabled: PropTypes.bool,
    fields: PropTypes.object,
    values: PropTypes.array,
};

export default FieldArrayNonDefaultConcepts;
