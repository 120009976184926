import React from 'react';

import { TableCell } from '@sunwisesoftware/sunwise-ui';
import PropTypes from 'prop-types';

import { ReactHookFormIntlNumberInputConsumption } from 'common/components/form/bootstrap';

import { BASE_COLORS, COLOR_DELTA } from '../../constants';
import { generateColorTone, getCellInputStyle } from '../../helpers';

import CollapsedElements from './CollapsedElements';

const EnergyCells = ({
    control,
    disabled,
    energyFields,
    energyTiers,
    handleChangeKWh,
    handleOnChangeDailyAvg,
    handleOnChangeTotal,
    handleOnPaste,
    hasBottomRightRadius,
    inputVariant,
    isCollapsed,
    isHourlyRate,
    name,
    showAvgDailyField,
    sxWarning,
}) => {
    const hasTiers = isHourlyRate && energyTiers?.length;
    const defaultStyle = getCellInputStyle(BASE_COLORS.ENERGY);
    const lastCellStyle = hasBottomRightRadius
        ? { ...defaultStyle, borderBottomRightRadius: '10px' }
        : defaultStyle;

    return (
        <CollapsedElements
            collapsedItem={
                <TableCell align="center" sx={lastCellStyle}>
                    <ReactHookFormIntlNumberInputConsumption
                        control={control}
                        disabled={disabled}
                        fullWidth
                        label=""
                        name={`${name}.total`}
                        onBlur={handleOnChangeTotal}
                        onPaste={(event) => handleOnPaste(event, 'total')}
                        sx={sxWarning}
                        variant={inputVariant}
                    />
                </TableCell>
            }
            isCollapsed={isCollapsed}
        >
            {!hasTiers && showAvgDailyField && (
                <TableCell align="center" sx={defaultStyle}>
                    <ReactHookFormIntlNumberInputConsumption
                        control={control}
                        disabled={disabled}
                        fullWidth
                        key={`${name}.dailyAvg`}
                        label=""
                        name={`${name}.dailyAvg`}
                        onBlur={handleOnChangeDailyAvg}
                        onPaste={(event) => handleOnPaste(event, 'dailyAvg')}
                        sx={sxWarning}
                        variant={inputVariant}
                    />
                </TableCell>
            )}

            {!hasTiers && !showAvgDailyField && (
                <TableCell align="center" sx={lastCellStyle}>
                    <ReactHookFormIntlNumberInputConsumption
                        control={control}
                        disabled={disabled}
                        fullWidth
                        key={`${name}.total`}
                        label=""
                        name={`${name}.total`}
                        onBlur={handleOnChangeTotal}
                        onPaste={(event) => handleOnPaste(event, 'total')}
                        sx={sxWarning}
                        variant={inputVariant}
                    />
                </TableCell>
            )}

            {hasTiers &&
                energyTiers.map((tier, column) => {
                    const tierKey = tier.name.toLowerCase();
                    const fieldName = `${name}.kWh.${tierKey}`;

                    const bgColor = generateColorTone(
                        BASE_COLORS.ENERGY,
                        column * COLOR_DELTA,
                    );
                    const isLastCell = column + 1 === energyTiers.length;
                    const style = getCellInputStyle(bgColor);

                    return (
                        <TableCell
                            key={tier.identifier}
                            sx={
                                hasBottomRightRadius && isLastCell
                                    ? {
                                          ...style,
                                          borderBottomRightRadius: '10px',
                                      }
                                    : style
                            }
                        >
                            {energyFields?.[tierKey] && (
                                <ReactHookFormIntlNumberInputConsumption
                                    allowDecimals={false}
                                    control={control}
                                    disabled={disabled}
                                    fullWidth
                                    name={fieldName}
                                    label=""
                                    onBlur={() => handleChangeKWh(fieldName)}
                                    onPaste={(event) =>
                                        handleOnPaste(event, `kWh.${tierKey}`)
                                    }
                                    sx={sxWarning}
                                    variant={inputVariant}
                                />
                            )}
                        </TableCell>
                    );
                })}
        </CollapsedElements>
    );
};

EnergyCells.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    energyFields: PropTypes.object,
    energyTiers: PropTypes.array,
    handleChangeKWh: PropTypes.func,
    handleOnChangeDailyAvg: PropTypes.func,
    handleOnChangeTotal: PropTypes.func,
    handleOnPaste: PropTypes.func,
    hasBottomRightRadius: PropTypes.bool,
    inputVariant: PropTypes.string,
    isCollapsed: PropTypes.bool,
    isHourlyRate: PropTypes.bool,
    name: PropTypes.string,
    showAvgDailyField: PropTypes.bool,
    sxWarning: PropTypes.object,
};

export default EnergyCells;
