import API from '../..';

const ENTITY = 'chargebee';

export const confirmSubscription = () =>
    API.post(`/api/v1/${ENTITY}/confirm-subscription/`);
export const generateCheckoutNewUrl = (data) =>
    API.post(`/api/v1/${ENTITY}/generate-checkout-new-url/`, {
        ...data,
    });
export const generatePortalSession = () =>
    API.post(`/api/v1/${ENTITY}/generate-portal-session/`);
export const getBuyCustomWebpage = (data) =>
    API.post(`/api/v1/${ENTITY}/buy-custom-webpage/`, { ...data });
export const getPlans = () => API.get(`/api/v1/${ENTITY}/plans/`); // Remover Front y Back
export const getPricing = () => API.get(`/api/v1/${ENTITY}/pricing/`);
export const payInvoices = () =>
    API.get(`/api/v1/${ENTITY}/invoices/autocollect/`);
