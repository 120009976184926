import API from '../..';
import templatesFastAPI from '../../templatesFastAPI';

const ENTITY = 'templates';

export const bulkTemplates = (data) =>
    API.put(`/api/v1/${ENTITY}/bulk-status-flags/`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
export const filterTemplates = ({
    language = 'es-mx',
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    proposalsNumber,
    searchText = '',
    sortBy = null,
    status = 'active',
    type,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            ...(language ? { language } : {}),
            order_by: orderBy,
            page,
            per_page: perPage,
            ...(proposalsNumber ? { proposals_number: proposalsNumber } : {}),
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
            type,
        },
    });
export const getTemplatesCatalog = () =>
    API.get(`/api/v1/${ENTITY}/catalog/`, { params: { language: 'es-mx' } });
export const getTemplatesToClone = (countryId = '', language = 'es-mx') =>
    API.get(`/api/v1/templates_to_clone/`, {
        params: { country: countryId, language },
    });
export const setClonePage = (offerCustomTemplateId, offerTemplateId, pages) =>
    API.put(
        `/api/v1/get_custom_template_pages/${offerCustomTemplateId}/${offerTemplateId}`,
        { pages },
    );

export const getCategories = ({ page_size = 100 } = {}) =>
    templatesFastAPI.get(`/api/v1/${ENTITY}/categories`, {
        params: { page_size },
    });

export const getTemplates = ({
    branch_office_id,
    is_base = false,
    is_global,
    language,
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    proposalsNumber,
    searchText = '',
    sortBy = null,
    status = 'active',
    type,
} = {}) =>
    templatesFastAPI.get(`/api/v1/${ENTITY}/`, {
        params: {
            branch_office_id,
            is_base,
            is_global,
            language,
            page,
            page_size: perPage,
            proposals_number: proposalsNumber,
            search: searchText,
            sort_by: orderBy,
            sort_type: sortBy === 'asc' ? 1 : -1,
            status,
            type,
        },
    });

const headers = {
    headers: { 'Content-Type': 'application/json' },
};

export const createTemplate = (data) =>
    templatesFastAPI.post(`/api/v1/${ENTITY}/`, data, headers);

export const updateTemplates = (data) =>
    templatesFastAPI.patch(`/api/v1/${ENTITY}/`, data, headers);

export const getTemplate = (templateId) =>
    templatesFastAPI.get(`/api/v1/${ENTITY}/${templateId}`);

export const updateTemplate = (templateId, data) =>
    templatesFastAPI.patch(`/api/v1/${ENTITY}/${templateId}`, data, headers);

export const duplicateTemplate = (templateId, data) =>
    templatesFastAPI.post(
        `/api/v1/${ENTITY}/${templateId}/duplicate`,
        data,
        headers,
    );

export const importTemplatePages = (templateId, data) =>
    templatesFastAPI.post(
        `/api/v1/${ENTITY}/${templateId}/pages/import`,
        data,
        headers,
    );

export const createTemplatePage = (templateId, data) =>
    templatesFastAPI.post(`/api/v1/${ENTITY}/${templateId}/pages`, data);

export const deleteTemplatePage = (templateId, pageNumber) =>
    templatesFastAPI.delete(
        `/api/v1/${ENTITY}/${templateId}/pages/${pageNumber}`,
    );
