import React, { useContext, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { Map } from 'google-maps-react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';

import { DEFAULT_LAT, DEFAULT_LNG } from 'common/constants';
import { GeneralContext } from 'common/utils/contexts';

const StyledMap = styled('div')`
    align-items: center;
    background-color: #c5c5c5;
    bottom: 0;
    display: flex;
    height: ${({ heightMap }) => (heightMap ? heightMap : '125px')};
    justify-content: center;
    left: 0;
    ${({ disabled }) => disabled && `opacity: .8;`}
    position: relative;
    right: 0;
    top: 0;
`;

const Container = ({
    center = { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
    disableDefaultUI = false,
    handleOnPositionChanged = null,
    heightMap,
    markers = [],
    onMapClicked,
    readOnly,
    zoom = 1,
}) => {
    const { google } = useContext(GeneralContext);
    const [mapValue, setMapValue] = useState(null);
    const [mapMarkers, setMapMarkers] = useState([]);

    useEffect(() => {
        if (mapValue && center) {
            var marks = [];
            markers.forEach((m) => {
                const tempMarker = new google.maps.Marker({
                    draggable: m.draggable,
                    title: m.title,
                    name: m.name,
                    position: new google.maps.LatLng(m.lat, m.lng),
                    onClick: m.onClick,
                    onDragend: m.onDragend,
                    map: mapValue,
                });

                marks.push(tempMarker);
            });
            setMapMarkers(marks);
            mapValue.setCenter({
                lat: parseFloat(center.lat),
                lng: parseFloat(center.lng),
            });
        }
    }, [mapValue]);

    useEffect(() => {
        if (mapValue && !isEmpty(center) && markers !== null) {
            if (center.lat !== null && center.lng !== null) {
                var latlng = new google.maps.LatLng(
                    parseFloat(center.lat),
                    parseFloat(center.lng),
                );

                mapMarkers.forEach((m) => {
                    m.setPosition(latlng);
                });
                mapValue.setCenter(latlng);
            }
        }
    }, [center, markers]);

    const draggable = !isUndefined(readOnly) ? !readOnly : false;

    return (
        <StyledMap disabled={readOnly} heightMap={heightMap}>
            <Map
                disableDefaultUI={disableDefaultUI}
                draggable={draggable}
                google={google}
                initialCenter={center}
                mapType="roadmap"
                onCenterChanged={(_, map) => {
                    var latlng = new google.maps.LatLng(
                        map.center.lat(),
                        map.center.lng(),
                    );
                    mapMarkers.forEach((m) => {
                        m.setPosition(latlng);
                    });
                }}
                onClick={onMapClicked}
                onDragend={(mapProps, map) => {
                    if (handleOnPositionChanged)
                        handleOnPositionChanged(mapProps, map);
                }}
                onReady={(p, map) => setMapValue(map)}
                onZoomChanged={(mapProps, map) => {
                    if (handleOnPositionChanged)
                        handleOnPositionChanged(mapProps, map);
                }}
                streetViewControl={false}
                zoom={zoom}
            />
        </StyledMap>
    );
};

Container.propTypes = {
    center: PropTypes.object,
    disableDefaultUI: PropTypes.bool,
    handleOnPositionChanged: PropTypes.func,
    heightMap: PropTypes.string,
    markers: PropTypes.array,
    onMapClicked: PropTypes.func,
    readOnly: PropTypes.bool,
    zoom: PropTypes.number,
};

export default Container;
