import API from '../..';
const ENTITY = 'branch-offices';

export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, { ...data });
export const deleteItem = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const getItems = (str, orderBy, sortBy, page) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            name: str,
            order_by: orderBy,
            page: page,
            sort: sortBy,
        },
    });
export const getNavbarBranchOffices = () =>
    API.get(`/api/v1/navbar/${ENTITY}/`);
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
