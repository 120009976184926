import API from '../..';
const ENTITY = 'historical';

export const create = (data) =>
    API.post(`/api/v1/${ENTITY}/`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
export const fetchData = (projectId) =>
    API.get(`/api/v1/${ENTITY}/${projectId}`);
export const fetchPaginatedData = (project_id, page, page_size, year) =>
    API.get(`/api/v1/paginated-${ENTITY}/`, {
        params: { page, page_size, project_id, year },
    });
export const fetchPriceData = (projectId, report) =>
    API.get(`/api/v1/${ENTITY}-reporter/${projectId}`, { params: { report } });
export const update = (uuid, data) =>
    API.put(`/api/v1/${ENTITY}/${uuid}`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
export const deleteItem = (uuid) => API.delete(`/api/v1/${ENTITY}/${uuid}`);
