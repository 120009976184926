import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import AccessRestrictedBanner from '../analytics/components/AccessRestrictedBanner';

const Container = ({ canView }) => {
    if (!canView) return null;
    return (
        <AccessRestrictedBanner>
            <Grid container sx={{ alignItems: 'center', height: '100%' }}>
                <Grid
                    item
                    xs={18}
                    sx={{
                        height: '100%',
                    }}
                >
                    <iframe
                        src="https://lookerstudio.google.com/embed/reporting/e1eaed94-d580-4c94-9b17-f103d27a1ae1"
                        style={{
                            border: 0,
                            height: 'calc(100% + 46px)',
                            width: '100%',
                        }}
                    />
                </Grid>
            </Grid>
        </AccessRestrictedBanner>
    );
};

Container.propTypes = {
    canView: PropTypes.bool,
};

export default withPermissions(PERMISSION_LIST.CFE_PERMISSION)(Container);
