import React, { useState, useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import { SERFIMEX_UID } from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import * as contactActions from '../../../../contactForm/actions';
import * as contactSelectors from '../../../../contactForm/selectors';
import * as actions from '../../../actions';
import { getFinancierProducts, getLabelButton } from '../../../helpers';
import * as selectors from '../../../selectors';
import { ciBancoFormValidate } from '../../../validate';
import FormStep1 from '../FormStep1';

import CIBancoFormStep2 from './CIBancoFormStep2';

const FormContainer = ({
    alliance,
    allianceProducts,
    canModifyContacts,
    contact,
    currencyIso,
    currencySymbol,
    fetchOfferDetails,
    initializeCibanco,
    initialValues,
    isLocked,
    isSaving,
    isSavingContact,
    proposalId,
    saveCiBanco,
    saveContact,
    total,
}) => {
    const [productSelected, setProductSelected] = useState({});

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        context: { contact, initialValues },
        defaultValues: initialValues,
        resolver: yupResolver(ciBancoFormValidate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const formValues = watch();
    const errors = get(productSelected, 'errors', []);
    const parameters = get(productSelected, 'financier_product_parameters', {});
    const step = get(formValues, 'step', 0);
    const typesBusinessCompany = get(formValues, 'types_business_company', '');
    const financierId = get(alliance, 'financier.id', null);
    const contactEmail = get(contact, 'email', null);
    const contactCompanyName = get(contact, 'company_name', null);
    const isSerfimex = financierId === SERFIMEX_UID;
    const shouldSaveContact =
        isSerfimex && (isEmpty(contactEmail) || isEmpty(contactCompanyName));

    useEffect(() => {
        if (!isEmpty(productSelected)) {
            initializeCibanco({
                ...productSelected,
                financier_installer: alliance.id,
                is_serfimex: isSerfimex,
                total,
                types_business_company: typesBusinessCompany,
            });
        }
    }, [productSelected]);

    const handleOnClickSave = (values) => {
        switch (step) {
            case 1:
                if (shouldSaveContact) {
                    saveContact({ ...values, contactId: contact.id }, () => {
                        fetchOfferDetails(proposalId);
                        setValue('step', 2);
                    });
                    return false;
                } else {
                    setValue('step', 2);
                    return false;
                }
            case 2:
                return saveCiBanco(proposalId, values);
            default:
                return false;
        }
    };

    const financierProducts = getFinancierProducts(
        allianceProducts,
        typesBusinessCompany,
    );

    return (
        <form>
            <FormStep1
                allianceProducts={allianceProducts}
                canModifyContacts={canModifyContacts}
                contactCompanyName={contactCompanyName}
                contactEmail={contactEmail}
                control={control}
                financierProducts={financierProducts}
                isLocked={isLocked}
                isSerfimex={isSerfimex}
                productSelected={productSelected}
                setProductSelected={setProductSelected}
                setValue={setValue}
                show={step === 1}
                typesBusinessCompany={typesBusinessCompany}
            />

            <CIBancoFormStep2
                alliance={alliance}
                control={control}
                currencyIso={currencyIso}
                currencySymbol={currencySymbol}
                formValues={formValues}
                isLocked={isLocked}
                parameters={parameters}
                productSelected={productSelected}
                setValue={setValue}
                show={step === 2}
                total={total}
            />

            <Grid container>
                <Grid item xs>
                    <ShowErrors errors={errors} />
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid item xs sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    <Button
                        disabled={
                            isLocked || isSavingContact || errors.length > 0
                        }
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleSubmit(handleOnClickSave)}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="button"
                    >
                        {getLabelButton({
                            isSaving: isSaving || isSavingContact,
                            step,
                        })}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesCiBanco,
    isSavingContact: contactSelectors.getIsSavingContact,
});

const mapDispatchToProps = (dispatch) => ({
    initializeCibanco: (values) =>
        dispatch(actions.initialValuesCiBanco(values)),
    saveCiBanco: (id, values) => dispatch(actions.saveCiBanco(id, values)),
    saveContact: (values, callback) =>
        dispatch(contactActions.saveSomeContactData(values, callback)),
});

FormContainer.propTypes = {
    alliance: PropTypes.object,
    allianceProducts: PropTypes.array,
    canModifyContacts: PropTypes.bool,
    contact: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    initializeCibanco: PropTypes.func,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    isSavingContact: PropTypes.bool,
    fetchOfferDetails: PropTypes.func,
    proposalId: PropTypes.string,
    saveCiBanco: PropTypes.func,
    saveContact: PropTypes.func,
    total: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
