import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CONTACTS,
    FETCH_CONTACTS_FAILURE,
    FETCH_CONTACTS_SUCCESS,
    FETCH_INDICATORS_DATA,
    FETCH_INDICATORS_DATA_FAILURE,
    FETCH_INDICATORS_DATA_SUCCESS,
    FETCH_MONITORING_DATA,
    FETCH_MONITORING_DATA_FAILURE,
    FETCH_MONITORING_DATA_SUCCESS,
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
    FETCH_PROJECT_BY_ID,
    FETCH_PROJECT_BY_ID_FAILURE,
    FETCH_PROJECT_BY_ID_SUCCESS,
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
    INITIALIZE_FILTERS,
    RESET,
    SET_IS_OPEN_FILTERS_MODAL,
} from './actionTypes';
import { NAME } from './constants';
import { getDefaultDates } from './helpers';

const INITIAL_STATE = {
    contacts: { data: [], errors: null, loading: false },
    indicatorsData: { data: {}, errors: null, loading: false },
    initialValuesFilters: getDefaultDates(),
    isOpenFiltersModal: false,
    monitoring: { data: {}, errors: null, loading: false },
    politicalDivisions: { data: [], errors: null, loading: false },
    project: { data: {}, errors: null, loading: false },
    rates: { data: [], errors: null, loading: false },
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CONTACTS]: (state) => {
            state.contacts.errors = null;
            state.contacts.loading = true;
        },
        [FETCH_CONTACTS_FAILURE]: (state, action) => {
            state.contacts.errors = action.payload;
            state.contacts.loading = false;
        },
        [FETCH_CONTACTS_SUCCESS]: (state, action) => {
            state.contacts.data = action.payload;
            state.contacts.loading = false;
        },
        [FETCH_INDICATORS_DATA]: (state) => {
            state.indicatorsData.data = {};
            state.indicatorsData.errors = null;
            state.indicatorsData.loading = true;
        },
        [FETCH_INDICATORS_DATA_FAILURE]: (state, action) => {
            state.indicatorsData.errors = action.payload;
            state.indicatorsData.loading = false;
        },
        [FETCH_INDICATORS_DATA_SUCCESS]: (state, action) => {
            state.indicatorsData.data = action.payload;
            state.indicatorsData.loading = false;
        },
        [FETCH_MONITORING_DATA]: (state) => {
            state.monitoring.data = {};
            state.monitoring.errors = null;
            state.monitoring.loading = true;
        },
        [FETCH_MONITORING_DATA_FAILURE]: (state, action) => {
            state.monitoring.errors = action.payload;
            state.monitoring.loading = false;
        },
        [FETCH_MONITORING_DATA_SUCCESS]: (state, action) => {
            state.monitoring.data = action.payload;
            state.monitoring.loading = false;
        },
        [FETCH_POLITICAL_DIVISIONS]: (state) => {
            state.politicalDivisions.errors = null;
            state.politicalDivisions.loading = true;
        },
        [FETCH_POLITICAL_DIVISIONS_FAILURE]: (state, action) => {
            state.politicalDivisions.errors = action.payload;
            state.politicalDivisions.loading = false;
        },
        [FETCH_POLITICAL_DIVISIONS_SUCCESS]: (state, action) => {
            state.politicalDivisions.data = action.payload;
            state.politicalDivisions.loading = false;
        },
        [FETCH_PROJECT_BY_ID]: (state) => {
            state.project.errors = null;
            state.project.loading = true;
        },
        [FETCH_PROJECT_BY_ID_FAILURE]: (state, action) => {
            state.project.errors = action.payload;
            state.project.loading = false;
        },
        [FETCH_PROJECT_BY_ID_SUCCESS]: (state, action) => {
            state.project.data = action.payload;
            state.project.loading = false;
        },
        [FETCH_RATES]: (state) => {
            state.rates.errors = null;
            state.rates.loading = true;
        },
        [FETCH_RATES_FAILURE]: (state, action) => {
            state.rates.errors = action.payload;
            state.rates.loading = false;
        },
        [FETCH_RATES_SUCCESS]: (state, action) => {
            state.rates.data = action.payload;
            state.rates.loading = false;
        },
        [INITIALIZE_FILTERS]: (state, action) => {
            state.initialValuesFilters = action.payload;
        },
        [RESET]: () => INITIAL_STATE,
        [SET_IS_OPEN_FILTERS_MODAL]: (state, action) => {
            state.isOpenFiltersModal = action.payload;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
