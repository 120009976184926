import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';

import {
    getFinancierProductsToSelect,
    getTypesBusiness,
    getTypeBusinessToSelect,
    normalizeFinancierProductField,
    normalizeRegimenField,
} from '../../helpers';

import LocationToggle from './LocationToggle';

const FormStep1 = ({
    allianceProducts,
    canModifyContacts,
    contactCompanyName,
    contactEmail,
    control,
    disabledLocationToggle = true,
    financierProducts,
    hasLocationToogle = false,
    isLocked,
    isSerfimex,
    productSelected,
    setProductSelected,
    setValue,
    show,
    typesBusinessCompany,
}) => {
    const { t } = useTranslation();

    if (!show) return false;

    const typesBusiness = getTypesBusiness(allianceProducts);

    return (
        <Grid container>
            <Grid item xs>
                <ReactHookFormSelect
                    control={control}
                    disabled={isLocked}
                    label={t('Type of tax system')}
                    name="types_business_company"
                    onChange={({ target: { value } }) =>
                        normalizeRegimenField(
                            setValue,
                            setProductSelected,
                        )(value)
                    }
                    options={getTypeBusinessToSelect(typesBusiness)}
                />
                <ReactHookFormSelect
                    control={control}
                    disabled={
                        isLocked ||
                        isEmpty(typesBusinessCompany) ||
                        financierProducts.length <= 0
                    }
                    label={t('Product')}
                    name="financier_product"
                    onChange={({ target: { value } }) =>
                        normalizeFinancierProductField(
                            setValue,
                            financierProducts,
                            setProductSelected,
                        )(value)
                    }
                    options={[
                        {
                            label: t('Select a product'),
                            value: '',
                            disabled: true,
                        },
                        ...getFinancierProductsToSelect(financierProducts),
                    ]}
                    sxFormControl={{ mt: 1 }}
                />
                {isSerfimex &&
                    !isEmpty(productSelected) &&
                    isEmpty(contactEmail) && (
                        <ReactHookFormInput
                            control={control}
                            disabled={!canModifyContacts}
                            label={t('Email').concat(' *')}
                            name="email"
                        />
                    )}
                {isSerfimex &&
                    typesBusinessCompany === 'company' &&
                    !isEmpty(productSelected) &&
                    isEmpty(contactCompanyName) && (
                        <ReactHookFormInput
                            control={control}
                            disabled={!canModifyContacts}
                            label={t('Business').concat(' *')}
                            name="company_name"
                        />
                    )}
                {hasLocationToogle && (
                    <LocationToggle
                        control={control}
                        disabled={disabledLocationToggle}
                    />
                )}
            </Grid>
        </Grid>
    );
};

FormStep1.propTypes = {
    allianceProducts: PropTypes.array,
    canModifyContacts: PropTypes.bool,
    contactCompanyName: PropTypes.string,
    contactEmail: PropTypes.string,
    control: PropTypes.object,
    disabledLocationToggle: PropTypes.bool,
    financierProducts: PropTypes.array,
    hasLocationToogle: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSerfimex: PropTypes.bool,
    productSelected: PropTypes.object,
    setProductSelected: PropTypes.func,
    setValue: PropTypes.func,
    show: PropTypes.bool,
    typesBusinessCompany: PropTypes.string,
};

export default FormStep1;
