import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import KeyIndicator from 'common/components/KeyIndicator';
import { cleanJSON, numberFormat } from 'common/utils/helpers';

import AllianceItem from './AllianceItem';

const AllianceReviewCeide = ({
    alliance,
    currencyIso,
    currencyLocale,
    financialProduct,
}) => {
    const { t } = useTranslation();
    const stringInfonavitData = get(financialProduct, 'infonavit_data', '{}');
    const infonavitData = JSON.parse(cleanJSON(stringInfonavitData));

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={9} md={4} lg={3}>
                <AllianceItem alliance={alliance} disabled />
            </Grid>
            <Grid item xs={18} lg={15}>
                <Grid container>
                    <Grid item xs={18} md={9} lg={4}>
                        <KeyIndicator
                            height={{ xs: 'auto', lg: '92px' }}
                            title={t(
                                'Amount available for photovoltaic installation',
                            )}
                            value={numberFormat(
                                infonavitData.available_balance,
                                {
                                    currency: currencyIso,
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'currency',
                                },
                            )}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={4}>
                        <KeyIndicator
                            height={{ xs: 'auto', lg: '92px' }}
                            title={t('Total amount')}
                            value={numberFormat(infonavitData.total_amount, {
                                currency: currencyIso,
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'currency',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={4}>
                        <KeyIndicator
                            height={{ xs: 'auto', lg: '92px' }}
                            title={t('Withholding on INFONAVIT account')}
                            value={numberFormat(
                                infonavitData.retention_amount,
                                {
                                    currency: currencyIso,
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'currency',
                                },
                            )}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={4}>
                        <KeyIndicator
                            height={{ xs: 'auto', lg: '92px' }}
                            title={t('Administrative expenses')}
                            value={numberFormat(
                                infonavitData.administratives_expenses_percentage_value,
                                {
                                    currency: currencyIso,
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'currency',
                                },
                            )}
                            widthIcon="20px"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

AllianceReviewCeide.propTypes = {
    alliance: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    financialProduct: PropTypes.object,
};

export default AllianceReviewCeide;
