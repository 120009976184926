import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import EmptyMessage from 'common/components/EmptyMessage';

import AllianceReviewCeide from './AllianceReviewCeide';
import AllianceReviewCIBanco from './AllianceReviewCIBanco';
import AllianceReviewRedGirasol from './AllianceReviewRedGirasol';

const AllianceReview = ({
    alliance,
    currencyIso,
    currencyLocale,
    financialProduct,
    financierType,
    handleClickAlliance,
    handleClickDeleteAlliance,
    hasFinancingRequest,
    isGeneratedProposal,
    isLoading,
    isLocked,
    proposalId,
    show,
}) => {
    const { t } = useTranslation();
    if (!show) return null;
    if (isLoading) {
        return (
            <EmptyMessage>
                <Typography variant="body2">
                    {t('Loading').concat('...')}
                </Typography>
            </EmptyMessage>
        );
    }
    if (isEmpty(financialProduct)) {
        return (
            <EmptyMessage>
                <Typography variant="body2">
                    {t('There is no data to show')}
                </Typography>
                <Typography variant="body2">
                    {t('Financial product is no longer available')}
                </Typography>
            </EmptyMessage>
        );
    }
    switch (financierType) {
        case 1:
            return (
                <AllianceReviewRedGirasol
                    alliance={alliance}
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    financialProduct={financialProduct}
                />
            );
        case 3:
            return (
                <AllianceReviewCeide
                    alliance={alliance}
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    financialProduct={financialProduct}
                />
            );
        default:
            return (
                <AllianceReviewCIBanco
                    alliance={alliance}
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    financialProduct={financialProduct}
                    handleClickAlliance={handleClickAlliance}
                    handleClickDeleteAlliance={handleClickDeleteAlliance}
                    hasFinancingRequest={hasFinancingRequest}
                    isGeneratedProposal={isGeneratedProposal}
                    isLocked={isLocked}
                    proposalId={proposalId}
                />
            );
    }
};

AllianceReview.propTypes = {
    alliance: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    financialProduct: PropTypes.object,
    financierType: PropTypes.number,
    handleClickAlliance: PropTypes.func,
    handleClickDeleteAlliance: PropTypes.func,
    hasFinancingRequest: PropTypes.bool,
    isGeneratedProposal: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    proposalId: PropTypes.string,
    show: PropTypes.bool,
};

export default AllianceReview;
