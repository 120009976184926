import { updateItem } from 'common/api/v1/integrations';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import fetchIntegrations from './fetchIntegrations';

export default (item) => (dispatch) => {
    var data = new FormData();
    data.append('name', item.name);

    updateItem(data, item.id).then(() => {
        dispatch(alerts.actions.close());
        dispatch(fetchIntegrations());

        showToast();
    });
};
