import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';

import { getFormatter, transformSeriesToRows } from '../helpers';

const Table = ({
    countryCurrencyLocale,
    handleSelectProject,
    isLoading,
    seriesData,
}) => {
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const newRows = transformSeriesToRows(seriesData?.series);
        setRows(newRows);
    }, [seriesData?.series]);

    const columns = [
        {
            field: 'project',
            flex: 1,
            headerName: t('Project'),
        },
        {
            field: 'generation',
            flex: 1,
            headerName: t('Annual generation'),
            renderCell: (params) =>
                getFormatter({ countryCurrencyLocale, val: params?.value }),
        },
    ];

    const onRowClick = ({ row }) => handleSelectProject(row?.id);

    return (
        <DataGrid
            checkboxSelection={false}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            componentsProps={{
                pagination: { rowsPerPageOptions: [15, 25, 50, 75, 100] },
            }}
            columns={columns || []}
            disableColumnMenu
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            hideFooterSelectedRowCount
            initialState={{ pagination: { pageSize: 15 } }}
            loading={isLoading}
            onRowClick={onRowClick}
            pagination
            rows={rows || []}
            sx={{
                height: '100%',
                '& .MuiDataGrid-cell:focus': { outline: 'none' },
                '& .MuiDataGrid-row': { cursor: 'pointer' },
            }}
        />
    );
};

Table.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    handleSelectProject: PropTypes.func,
    isLoading: PropTypes.bool,
    seriesData: PropTypes.object,
};

export default Table;
