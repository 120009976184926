export const NAME = 'proposal-indicators';

export const PRODUCTS_TYPE = {
    0: 'panels',
    1: 'inverters',
    2: 'accesories',
    3: 'structures',
    4: 'workforce',
    5: 'additional',
    6: 'batteries',
};

export const INDICATORS = {
    actual_exported_energy: { name: 'Exported generation' },
    actual_exported_energy_percentage: {
        complement: 'Percentage',
        name: 'Exported generation',
    },
    actual_grid_consumption: { name: 'Consumption from Grid' },
    actual_grid_consumption_percentage: {
        complement: 'Percentage',
        name: 'Consumption from Grid',
    },
    actual_self_consumption: { name: 'Self-consumption' },
    actual_self_consumption_percentage: {
        complement: 'Percentage',
        name: 'Self-consumption',
    },
    annual_saving: { name: 'Total annual savings' },
    annual_saving_percentage: { name: 'Annual savings percentage' },
    average_daily_consumption: { name: 'Average daily consumption' },
    current_rate: { name: 'Current rate' },
    daily_average_generation: { name: 'Daily average generation' },
    daily_storage_percentage: { name: 'Daily storage percentage' },
    energy: { name: 'Energy saving' },
    energy_compensation_scheme: { name: 'Energy compensation scheme' },
    final_rate: { name: 'Proposed rate' },
    full_backup_duration: { name: 'Full backup duration' },
    gross_storage_generation: { name: 'Gross storage capacity' },
    initial_rate: { name: 'Initial rate' },
    internal_rate_return: { name: 'Internal rate of return' },
    'kWh/kW': { name: 'kWh/kW' },
    max_daily_consumption: { name: 'Max daily consumption' },
    net_present_value: { name: 'Net present value' },
    original_consumption: { name: 'Original consumption' },
    original_rate: { name: 'Original rate' },
    price_watt: { name: 'Dolar per watt' },
    price_watt_local: { name: 'Price per watt' },
    rate: { name: 'Rate' },
    return_of_investment: { name: 'Return on investment' },
    self_consumption_utilization_percentage: {
        complement: 'Percentage',
        name: 'Self-consumption utilization',
    },
    storage_price_gross_per_kwh: { name: 'Gross storage price' },
    storage_price_per_kwh: { name: 'Net storage price' },
    sun_hrs: { name: 'Sun Hours' },
    system_generation: { name: 'System generation' },
    system_size: { name: 'System size' },
    total_price: { name: 'Subtotal without taxes' },
    total_price_iva: { name: 'Total with taxes' },
    zero_export_utilization_percentage: { name: 'Zero export utilization' },
};
