import API from '../..';
const ENTITY = 'panels-companies';

export const addProductToBranches = (data) =>
    API.post(`/api/v1/${ENTITY}/`, { ...data });
export const exportFile = ({
    key,
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    personalized,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/export-upload/`, {
        params: personalized
            ? {
                  key,
                  order_by: orderBy,
                  page: page,
                  per_page: perPage,
                  personalized,
                  search: searchText,
                  ...(searchText ? { search: searchText } : {}),
                  sort: sortBy,
                  status_flag: status,
              }
            : {},
        headers: { 'Content-Type': 'text/csv' },
    });
export const getItems = (branchOffice) =>
    API.get(`/api/v1/${ENTITY}/`, { params: { branch_office: branchOffice } });
export const importFile = (data) =>
    API.post(`/api/v1/${ENTITY}/export-upload/`, { ...data });
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
