import { NAME } from './constants';

export const FETCH_ALLIANCE_PRODUCTS = `${NAME}/FETCH_ALLIANCE_PRODUCTS`;
export const FETCH_ALLIANCE_PRODUCTS_FAILURE = `${NAME}/FETCH_ALLIANCE_PRODUCTS_FAILURE`;
export const FETCH_ALLIANCE_PRODUCTS_SUCCESS = `${NAME}/FETCH_ALLIANCE_PRODUCTS_SUCCESS`;

export const FETCH_ALLIANCE_PRODUCT_WARNINGS = `${NAME}/FETCH_ALLIANCE_PRODUCT_WARNINGS`;
export const FETCH_ALLIANCE_PRODUCT_WARNINGS_FAILURE = `${NAME}/FETCH_ALLIANCE_PRODUCT_WARNINGS_FAILURE`;
export const FETCH_ALLIANCE_PRODUCT_WARNINGS_SUCCESS = `${NAME}/FETCH_ALLIANCE_PRODUCT_WARNINGS_SUCCESS`;

export const DELETE_FINANCIAL_PRODUCT = `${NAME}/ DELETE_FINANCIAL_PRODUCT`;
export const DELETE_FINANCIAL_PRODUCT_FAILURE = `${NAME}/ DELETE_FINANCIAL_PRODUCT_FAILURE`;
export const DELETE_FINANCIAL_PRODUCT_SUCCESS = `${NAME}/ DELETE_FINANCIAL_PRODUCT_SUCCESS`;

export const FETCH_FINANCIAL_PRODUCTS_PLAN = `${NAME}/ FETCH_FINANCIAL_PRODUCTS_PLAN`;
export const FETCH_FINANCIAL_PRODUCTS_PLAN_FAILURE = `${NAME}/ FETCH_FINANCIAL_PRODUCTS_PLAN_FAILURE`;
export const FETCH_FINANCIAL_PRODUCTS_PLAN_SUCCESS = `${NAME}/ FETCH_FINANCIAL_PRODUCTS_PLAN_SUCCESS`;

export const FETCH_FINANCIER_INSTALLERS = `${NAME}/FETCH_FINANCIER_INSTALLERS`;
export const FETCH_FINANCIER_INSTALLERS_FAILURE = `${NAME}/FETCH_FINANCIER_INSTALLERS_FAILURE`;
export const FETCH_FINANCIER_INSTALLERS_SUCCESS = `${NAME}/FETCH_FINANCIER_INSTALLERS_SUCCESS`;

export const FETCH_FINANCIERS = `${NAME}/FETCH_FINANCIERS`;
export const FETCH_FINANCIERS_FAILURE = `${NAME}/FETCH_FINANCIERS_FAILURE`;
export const FETCH_FINANCIERS_SUCCESS = `${NAME}/FETCH_FINANCIERS_SUCCESS`;

export const FETCH_INFONAVIT_INFO = `${NAME}/FETCH_INFONAVIT_INFO`;
export const FETCH_INFONAVIT_INFO_FAILURE = `${NAME}/FETCH_INFONAVIT_INFO_FAILURE`;
export const FETCH_INFONAVIT_INFO_SUCCESS = `${NAME}/FETCH_INFONAVIT_INFO_SUCCESS`;

export const FETCH_REQUIREMENTS = `${NAME}/FETCH_REQUIREMENTS`;
export const FETCH_REQUIREMENTS_FAILURE = `${NAME}/FETCH_REQUIREMENTS_FAILURE`;
export const FETCH_REQUIREMENTS_SUCCESS = `${NAME}/FETCH_REQUIREMENTS_SUCCESS`;

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;
export const INITIAL_VALUES_CEIDE = `${NAME}/INITIAL_VALUES_CEIDE`;
export const INITIAL_VALUES_CI_BANCO = `${NAME}/INITIAL_VALUES_CI_BANCO`;
export const INITIAL_VALUES_RED_GIRASOL = `${NAME}/INITIAL_VALUES_RED_GIRASOL`;

export const REFRES_FINANCING = `${NAME}/REFRES_FINANCING`;
export const REFRES_FINANCING_FAILURE = `${NAME}/REFRES_FINANCING_FAILURE`;
export const REFRES_FINANCING_SUCCESS = `${NAME}/REFRES_FINANCING_SUCCESS`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_FORM_CEIDE = `${NAME}/RESET_FORM_CEIDE`;
export const RESET_FORM_CI_BANCO = `${NAME}/RESET_FORM_CI_BANCO`;
export const RESET_FORM_RED_GIRASOL = `${NAME}/RESET_FORM_RED_GIRASOL`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SAVE_FINANCIERS_INSTALLER = `${NAME}/SAVE_FINANCIERS_INSTALLER`;
export const SAVE_FINANCIERS_INSTALLER_FAILURE = `${NAME}/SAVE_FINANCIERS_INSTALLER_FAILURE`;
export const SAVE_FINANCIERS_INSTALLER_SUCCESS = `${NAME}/SAVE_FINANCIERS_INSTALLER_SUCCESS`;

export const SET_IS_OPEN_ADD_ALLIANCE_MODAL = `${NAME}/SET_IS_OPEN_ADD_ALLIANCE_MODAL`;
export const SET_IS_OPEN_ALLIANCE_MODAL = `${NAME}/SET_IS_OPEN_ALLIANCE_MODAL`;
export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;
