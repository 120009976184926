import API from '../..';
const ENTITY = 'ps-ts-hourly-configuration';

export const getConfig = (commercialOfferId) =>
    API.get(`/api/v2/${ENTITY}/${commercialOfferId}`);

export const saveConfig = (commercialOfferId, values) =>
    API.put(
        `/api/v2/${ENTITY}/${commercialOfferId}`,
        { ...values },
        { headers: { 'Content-Type': 'application/json' } },
    );
