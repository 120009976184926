import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import KeyIndicator from 'common/components/KeyIndicator';

import { normalizeTermField, valueToPercentage } from '../../../helpers';
import AllianceItem from '../AllianceItem';
import HitchField from '../HitchField';

const RedGirasolFormStep2 = ({
    alliance,
    control,
    currencyIso,
    currencySymbol,
    formValues,
    isLocked,
    parameters,
    productSelected,
    setValue,
    show,
    total,
}) => {
    const { t } = useTranslation();

    if (!show) return false;

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={9} md={4} lg={3}>
                    <AllianceItem alliance={alliance} />
                </Grid>
            </Grid>
            <Grid container justifyContent="center" mt={2}>
                <Grid item xs={18} md={6} lg={4}>
                    <KeyIndicator
                        title={t('Interest rate')}
                        unit="%"
                        value={formValues.rate}
                        widthIcon="20px"
                    />
                </Grid>
                <Grid item xs={18} md={6} lg={4}>
                    <KeyIndicator
                        title={t('Opening commission')}
                        unit="%"
                        value={valueToPercentage(
                            formValues.is_commission_percentage,
                            formValues.commission_credit,
                            total,
                        )}
                        widthIcon="20px"
                    />
                </Grid>
                {formValues.residual > 0 && (
                    <Grid item xs={18} md={6} lg={4}>
                        <KeyIndicator
                            title={t('Residual')}
                            unit="%"
                            value={formValues.residual}
                            widthIcon="20px"
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container mt={2}>
                <Grid item xs>
                    <Typography variant="body2" fontWeight="bold">
                        {productSelected.name}
                    </Typography>

                    <Typography variant="caption">
                        {productSelected.description}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mt={1}>
                <Grid item xs={18} lg={9}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isLocked}
                        label={t('Term')}
                        name="term"
                        normalize={({ target: { value } }) =>
                            normalizeTermField(
                                setValue,
                                formValues,
                                parameters,
                            )(value)
                        }
                        options={[
                            {
                                label: t('Select a term'),
                                value: '',
                                disabled: true,
                            },
                            ...formValues.termsToSelect,
                        ]}
                        sx={{ mb: '0 !important' }}
                    />
                </Grid>
                <Grid item xs={18} lg={9}>
                    <HitchField
                        control={control}
                        currencyIso={currencyIso}
                        currencySymbol={currencySymbol}
                        formValues={formValues}
                        isLocked={isLocked}
                        parameters={parameters}
                        setValue={setValue}
                        total={total}
                    />
                </Grid>
            </Grid>
        </>
    );
};

RedGirasolFormStep2.propTypes = {
    alliance: PropTypes.object,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    formValues: PropTypes.object,
    isLocked: PropTypes.bool,
    parameters: PropTypes.object,
    productSelected: PropTypes.object,
    setValue: PropTypes.func,
    show: PropTypes.bool,
    total: PropTypes.number,
};

export default RedGirasolFormStep2;
