import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';
import i18next from 'i18next';

import {
    formatDate,
    parseDate,
    subDate,
    transformDateFormat,
} from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import parseQueryString from 'common/utils/parseQueryString';

import {
    COLORS,
    DATE_FORMATS,
    DATE_FREQUENCIES,
    FILTERS_KEYS,
    MAX_DATE,
    RATE_ID_FILTERS,
    STATUS_CONFIG,
    STATUS_KEYS,
    SVG_BASE_MARKER,
} from './constants';

const applyAfterSalesDateFormat = (date, format = DATE_FORMATS.AFTER_SALES) =>
    formatDate(date, format);

export const getContactName = ({
    first_lastname,
    name,
    second_lastname,
} = {}) => `${name || ''} ${first_lastname || ''} ${second_lastname || ''}`;

export const getDefaultDates = () => ({
    final_date: formatDate(MAX_DATE, DATE_FORMATS.SUNWISE),
    freq: DATE_FREQUENCIES.MONTHLY,
    initial_date: formatDate(
        subDate(MAX_DATE, { months: 11 }),
        DATE_FORMATS.SUNWISE,
    ),
});

export const getDateRangeLabel = (initialDate, finalDate, dateFormat) => {
    if (!initialDate && !finalDate) return null;
    const initial = transformDateFormat(initialDate, dateFormat);
    const final = transformDateFormat(finalDate, dateFormat);
    return `${initial} - ${final}`;
};

export const getAfterSalesDateRange = ({ final_date, freq, initial_date }) => {
    if (!final_date || !initial_date) return '';
    const initial = parseDate(initial_date, DATE_FORMATS.SUNWISE);
    const final = parseDate(final_date, DATE_FORMATS.SUNWISE);

    switch (freq) {
        case DATE_FREQUENCIES.YEARLY:
            return {
                final_date: applyAfterSalesDateFormat(endOfYear(final)),
                freq,
                initial_date: applyAfterSalesDateFormat(startOfYear(initial)),
            };
        case DATE_FREQUENCIES.DAILY:
            return {
                final_date: applyAfterSalesDateFormat(final),
                freq,
                initial_date: applyAfterSalesDateFormat(initial),
            };
        default:
            return {
                final_date: applyAfterSalesDateFormat(endOfMonth(final)),
                freq: DATE_FREQUENCIES.MONTHLY,
                initial_date: applyAfterSalesDateFormat(startOfMonth(initial)),
            };
    }
};

const transformObjectToSelectOptions = (object) => {
    if (!object) return [];

    const grouped = Object.values(object).reduce((acc, option) => {
        const { group = '', label, value } = option || {};
        if (!acc[group]) acc[group] = [];
        acc[group].push({ label, value });
        return acc;
    }, {});

    return Object.entries(grouped).map(([group, options]) => ({
        label: group || '',
        options,
    }));
};

export const getFilteredSelectOptions = ({
    agentId,
    contactId,
    contacts,
    projectStatusId,
    projects,
    rateId,
}) => {
    if (!projects?.length)
        return { contacts: [], rates: [], projects: [], projectStatuses: [] };

    const filteredRates = {};
    const filteredProjects = {};
    const filteredContacts = {};
    const filteredProjectStatuses = {};

    for (const contact of contacts) {
        const { agent, id } = contact || {};
        if (!id || (agentId && agent?.id !== agentId)) continue;

        filteredContacts[id] = { label: getContactName(contact), value: id };
    }

    for (const project of projects) {
        const { contact, id, name, status_project, rate } = project || {};
        if (
            !id ||
            !contact ||
            !rate ||
            !filteredContacts[contact?.id] ||
            (contactId && contact.id !== contactId)
        )
            continue;

        filteredProjectStatuses[status_project?.id] = {
            label: status_project?.name,
            value: status_project?.id,
        };

        if (projectStatusId && status_project?.id !== projectStatusId) continue;

        filteredRates[rate.id] = {
            group: rate.certified
                ? i18next.t('Certificated')
                : i18next.t('Custom', { context: 'female', count: 2 }),
            label: rate.name,
            value: rate.id,
        };

        if (rateId && project.rate.id !== rateId) continue;

        filteredProjects[id] = { label: name, value: id };
    }
    return {
        contacts: transformObjectToSelectOptions(filteredContacts),
        projectStatuses: transformObjectToSelectOptions(
            filteredProjectStatuses,
        ),
        projects: transformObjectToSelectOptions(filteredProjects),
        rates: transformObjectToSelectOptions(filteredRates),
    };
};

export const getFiltersFromQuery = (query) => {
    const parsedQuery = parseQueryString(query || '');

    const filtersKeys = Object.values(FILTERS_KEYS);
    const filters = filtersKeys.reduce((acc, key) => {
        if (parsedQuery[key] || parsedQuery[key] === 0)
            acc[key] = parsedQuery[key];
        return acc;
    }, {});

    const invalidRateKey = filters[RATE_ID_FILTERS.NOT_CERTIFIED]
        ? RATE_ID_FILTERS.CERTIFIED
        : RATE_ID_FILTERS.NOT_CERTIFIED;

    delete filters[invalidRateKey];

    if (
        !parsedQuery?.[FILTERS_KEYS.INITIAL_DATE] ||
        !parsedQuery?.[FILTERS_KEYS.FINAL_DATE] ||
        !parsedQuery?.[FILTERS_KEYS.FREQ]
    )
        return { ...filters, ...getDefaultDates() };

    return filters;
};

export const getQueryFromFilters = (filters) => {
    const filtersKeys = Object.values(FILTERS_KEYS);

    const queries = [];

    filtersKeys.forEach((key) => {
        if (filters[key] || filters[key] === 0)
            queries.push(`${key}=${filters[key]}`);
    });
    if (!queries.length) return '';
    return `?${queries.join('&')}`;
};

export const getFilterNameFromOptions = (id, options) => {
    if (!id || !options?.length) return;

    for (const option of options) {
        const item = option?.options?.find((item) => item.value === id);
        if (item) return item?.label;
    }

    return null;
};

export const getPercentageVariation = (value, previousValue) => {
    if (!previousValue) return 0;
    if (!value) return -100;
    return ((value - previousValue) / previousValue) * 100 || 0;
};

// Status
export const getColorByStatus = (status) => {
    const statusConfig = STATUS_CONFIG[status];
    return statusConfig?.color ?? STATUS_CONFIG[STATUS_KEYS.ONLINE].color;
};

export const getMarkerIconByStatus = (status) => {
    const color = getColorByStatus(status);
    const markerIcon = SVG_BASE_MARKER.replace('{{fillColor}}', color);
    return getSvgUrl(markerIcon);
};

export const getSvgUrl = (svg) =>
    `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;

// Charts
export const getFormatter = ({ countryCurrencyLocale, unit, val }) =>
    numberFormat(val, {
        decimals: 2,
        locale: countryCurrencyLocale,
        style: 'decimal',
        unit,
    });

export const getChartOptions = ({
    categories,
    colors,
    countryCurrencyLocale,
    handleSelectProject,
    pointsSelection = true,
    series,
    unit = 'kWh',
}) => {
    const formatter = (val) =>
        getFormatter({ countryCurrencyLocale, unit, val });

    const tooltipConfig = pointsSelection
        ? { intersect: true, shared: false }
        : { intersect: false, shared: true };
    const markers = pointsSelection ? { size: 4, strokeWidth: 0 } : { size: 0 };

    return {
        chart: {
            animations: { speed: 300 },
            background: '#ffffff00',
            events: {
                dataPointSelection: (_, __, config) => {
                    if (!pointsSelection) return;
                    const serie = series?.[config?.seriesIndex];
                    handleSelectProject(serie?.projectId);
                },
            },
            toolbar: { show: false },
            zoom: { enabled: false },
        },
        colors,
        dataLabels: { enabled: false },
        fill: { opacity: 1 },
        legend: { show: false },
        stroke: { curve: 'smooth', width: 2 },
        tooltip: { ...tooltipConfig, y: { formatter } },
        markers,
        xaxis: { categories, tickAmount: 12 },
        yaxis: {
            decimalsInFloat: 0,
            labels: { formatter },
            tickAmount: 4,
        },
    };
};

export const buildChartJsConfig = ({
    categories,
    colors,
    countryCurrencyLocale,
    handleSelectProject,
    pointsSelection = true,
    series,
    unit = 'kWh',
}) => {
    const formatter = (val) =>
        getFormatter({ countryCurrencyLocale, unit, val });

    const data = {
        datasets: series.map(({ data, name }, index) => {
            const color = colors[index % colors.length];
            return {
                backgroundColor: color,
                borderColor: color,
                data,
                fill: false,
                label: name,
                pointRadius: 5,
            };
        }),
        labels: categories,
    };

    const options = {
        onClick: (_, elements) => {
            if (elements.length > 0 && pointsSelection) {
                const element = elements[0];
                const datasetIndex = element.datasetIndex;
                const serie = series?.[datasetIndex];
                if (handleSelectProject) handleSelectProject(serie?.projectId);
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return (
                            context.dataset.label +
                            ': ' +
                            formatter(context.parsed.y)
                        );
                    },
                },
            },
        },
        scales: {
            x: { grid: { display: false } },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: (val) => formatter(val),
                    count: 12,
                },
            },
        },
    };

    return { data, options };
};

export const getDateFromNumbers = ({ day, month, year }) => {
    const _year = setNumberToDigits(year, 4);
    const _month = setNumberToDigits(month ?? 0);
    const _day = setNumberToDigits(day ?? 1);
    return `${_year}-${_month}-${_day}`;
};

export const getProjectColorByIndex = (index) => {
    if (index >= 0) return COLORS[index % COLORS.length];
    return COLORS[0];
};

export const getLabel = (date, freq) => {
    switch (freq) {
        case DATE_FREQUENCIES.YEARLY:
            return formatDate(date, 'yyyy');
        case DATE_FREQUENCIES.DAILY:
            return formatDate(date, 'dd');
        default:
            return formatDate(date, 'MMM yy');
    }
};

export const setNumberToDigits = (number, digits = 2) => {
    if (typeof number !== 'number') return number;
    return number.toString().padStart(digits, '0');
};

export const getGroupedData = ({ freq, reads }) => {
    const groupedReadsData = {};
    const dates = new Set();

    for (const read of reads) {
        if (!read) continue;
        const { sunwise_project, year, month, day, generation } = read;
        const _generation = Number(generation);

        if (!_generation) continue;
        if (!groupedReadsData[sunwise_project])
            groupedReadsData[sunwise_project] = [];

        const date = getDateFromNumbers({ day, month, year });
        dates.add(date);

        if (!groupedReadsData[sunwise_project][date])
            groupedReadsData[sunwise_project][date] = 0;

        groupedReadsData[sunwise_project][date] += generation;
    }

    const parsedDates = Array.from(dates).map((date) => {
        const parsedDate = parseDate(date, 'yyyy-MM-dd');
        return {
            date,
            label: getLabel(parsedDate, freq)?.toUpperCase(),
            time: parsedDate.getTime(),
        };
    });
    parsedDates.sort((a, b) => a.time - b.time);

    return { groupedReadsData, parsedDates };
};

export const getGenerationSeriesData = ({ freq, projects, reads }) => {
    if (!reads?.length || !projects?.length)
        return { categories: [], colors: [], series: [] };

    const { groupedReadsData, parsedDates } = getGroupedData({ freq, reads });

    const categories = parsedDates.map(({ label }) => label);
    const colors = [];
    const series = [];

    for (const [projectId, data] of Object.entries(groupedReadsData)) {
        const projectIndex = projects.findIndex(
            (project) => project.id === projectId,
        );
        colors.push(getProjectColorByIndex(projectIndex));

        const orderedData = parsedDates.map(({ date }) => data[date] || 0);
        series.push({
            data: orderedData,
            name: projects[projectIndex]?.name || 'N/A',
            projectId,
        });
    }

    return { categories, colors, series };
};

export const transformSeriesToRows = (series) => {
    if (!series?.length) return [];

    return series.map((serie) => {
        const { data, name, projectId } = serie;
        const generation = data.reduce((acc, value) => acc + (value || 0), 0);
        return { id: projectId, project: name, generation };
    });
};
