export const NAME = 'monitoring-dashboard';

export const COLORS = [
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#F2CC80',
    '#00B3E6',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
];

export const DATE_FORMATS = {
    SUNWISE: 'dd/MM/yyyy',
    AFTER_SALES: 'yyyy-MM-dd',
};

export const DATE_FREQUENCIES = {
    DAILY: 'D',
    MONTHLY: 'M',
    YEARLY: 'Y',
};

export const RATE_ID_FILTERS = {
    CERTIFIED: 'rate_id',
    NOT_CERTIFIED: 'customize_rate_id',
};

export const FILTERS_KEYS = {
    AGENT: 'agent_id',
    CONTACT_ID: 'contact_id',
    CUSTOM_RATE_ID: RATE_ID_FILTERS.NOT_CERTIFIED,
    FINAL_DATE: 'final_date',
    FREQ: 'freq',
    INITIAL_DATE: 'initial_date',
    POLITICAL_DIVISION: 'political_division1_id',
    PROJECT_ID: 'sunwise_project_id',
    PROJECT_STATUS: 'sw_project_status_id',
    PROVIDER: 'provider',
    RATE_ID: RATE_ID_FILTERS.CERTIFIED,
    STATUS: 'status',
    ZIP_CODE: 'zip_code',
};

export const FILTERS_TO_DISPLAY = [
    FILTERS_KEYS.AGENT,
    FILTERS_KEYS.CONTACT_ID,
    FILTERS_KEYS.PROJECT_STATUS,
    FILTERS_KEYS.PROJECT_ID,
    FILTERS_KEYS.RATE_ID,
    FILTERS_KEYS.POLITICAL_DIVISION,
    FILTERS_KEYS.ZIP_CODE,
    FILTERS_KEYS.STATUS,
    FILTERS_KEYS.PROVIDER,
];

export const MAX_DATE = new Date();

export const STATUS_KEYS = {
    OFFLINE: 2,
    ONLINE: 0,
    UNSTABLE: 1,
};

export const STATUS_CONFIG = {
    [STATUS_KEYS.UNSTABLE]: { color: '#fdcb6e', label: 'Unstable' },
    [STATUS_KEYS.ONLINE]: { color: '#00b894', label: 'Online' },
    [STATUS_KEYS.OFFLINE]: { color: '#d63031', label: 'Offline' },
};

export const SVG_BASE_CLUSTER = `<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#00B4D8" viewBox="0 0 240 240"><circle cx="120" cy="120" r="70" opacity=".6"/><circle cx="120" cy="120" r="90" opacity=".3"/><circle cx="120" cy="120" r="110" opacity=".2"/><text x="50%" y="50%" dominant-baseline="middle" font-family="Montserrat, sans-serif" font-size="50" style="fill:#fff" text-anchor="middle">{{count}}</text></svg>`;
export const SVG_BASE_MARKER = `<svg fill="none" viewBox="0 0 32 43" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_f_1003_3905)"><ellipse cx="12" cy="29" fill="#000" fill-opacity=".12" rx="4" ry="2"/></g><path fill="{{fillColor}}" fill-rule="evenodd" d="M23.888 26.75A13.315 13.315 0 0029.333 16c0-7.364-5.97-13.334-13.333-13.334C8.636 2.666 2.666 8.636 2.666 16c0 4.412 2.144 8.324 5.446 10.75 2.51 1.892 6.046 4.874 6.86 9.676.086.508.512.903 1.028.903s.942-.395 1.028-.903c.813-4.802 4.35-7.784 6.86-9.675z" clip-rule="evenodd"/><path fill="#EEF4FA" d="M14.818 24a.513.513 0 01-.507-.587l.8-5.635H12c-.782 0-.293-.667-.276-.694 1.12-1.982 2.8-4.915 5.023-8.826a.513.513 0 01.951.329l-.8 5.635h3.12c.355 0 .55.17.355.587-2.924 5.102-4.622 8.08-5.111 8.933a.504.504 0 01-.444.258z"/><path fill="#fff" fill-opacity=".9" d="M14.818 24a.513.513 0 01-.507-.587l.8-5.635H12c-.782 0-.293-.667-.276-.694 1.12-1.982 2.8-4.915 5.023-8.826a.513.513 0 01.951.329l-.8 5.635h3.12c.355 0 .55.17.355.587-2.924 5.102-4.622 8.08-5.111 8.933a.504.504 0 01-.444.258z"/><defs><filter id="filter0_f_1003_3905" width="12" height="8" x="6" y="25" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur result="effect1_foregroundBlur_1003_3905" stdDeviation="1"/></filter></defs></svg>`;

export const VIEWS = {
    MAP: 'map',
    CHART: 'chart',
    TABLE: 'table',
};
