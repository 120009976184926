import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import detectOnline from 'common/hocs/detectOnline';
import withPermissions from 'common/hocs/withPermissions';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import * as funnelSocket from 'common/sockets/funnel';
import { getSessionLocalStorage } from 'common/utils/helpers/session';
import showToast from 'common/utils/showToast';

import * as projectActions from '../project/actions';
import * as projectSelectors from '../project/selectors';
import ProjectPreviewModal from '../quickViewProjects';
import * as quickViewProjectsActions from '../quickViewProjects/actions';

import * as actions from './actions';
import Column from './components/Column';
import ColumnsWrapper from './components/ColumnsWrapper';
import PlaceholderColumn from './components/PlaceholderColumn';
import * as selectors from './selectors';

const Container = ({
    branchOfficesDictionary,
    canModify,
    connectFunnelSocket,
    disconnectSocket,
    fetchFunnelProjects,
    fetchNextPage,
    fetchStatusProject,
    filterQuery,
    funnelColumns,
    handleOpenPreviewModal,
    isLoadingProjects,
    isOnline,
    nextPagesIds,
    refreshingIsNeeded,
    statusProject,
    updateColumns,
    updatingStatusProjectId,
}) => {
    const { t } = useTranslation();
    const [currentProjectId, setCurrentProjectId] = useState(null);
    const session = getSessionLocalStorage();

    useEffect(() => {
        if (session?.id) {
            connectFunnelSocket(session.id, branchOfficesDictionary);
        }
        fetchStatusProject();
        return () => {
            disconnectSocket();
        };
    }, []);

    useEffect(() => {
        if (!refreshingIsNeeded) return;
        showToast({
            body: t('Changes have been detected. You need to refresh the page'),
            type: 'warning',
            autoClose: false,
        });
    }, [refreshingIsNeeded]);

    useEffect(() => {
        fetchFunnelProjects(filterQuery);
    }, [filterQuery]);

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result;
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;
        updateColumns(
            source.droppableId,
            destination.droppableId,
            draggableId,
            destination.index,
            source.index,
        );
    };

    const sortedColumns = sortBy(
        Object.keys(funnelColumns).map((key) => funnelColumns[key]),
        (o) => o.order,
    );

    return (
        <>
            <ColumnsWrapper>
                <PlaceholderColumn
                    isEmpty={isEmpty(sortedColumns)}
                    ready={!isLoadingProjects}
                >
                    <DragDropContext onDragEnd={onDragEnd}>
                        {sortedColumns.map((sortedColumn) => {
                            const isLoadingCurrentNextPage =
                                nextPagesIds[sortedColumn.id];

                            return (
                                <Column
                                    canModify={canModify}
                                    color={
                                        statusProject[sortedColumn.id]
                                            ?.primary_color
                                    }
                                    fetchNextPage={(status, nextPage) =>
                                        fetchNextPage(
                                            status,
                                            nextPage,
                                            filterQuery,
                                        )
                                    }
                                    handleClickView={(id) => {
                                        setCurrentProjectId(id);
                                        handleOpenPreviewModal();
                                    }}
                                    isLoadingCurrentNextPage={
                                        isLoadingCurrentNextPage
                                    }
                                    id={sortedColumn.id}
                                    key={sortedColumn.id}
                                    isOnline={isOnline}
                                    refreshingIsNeeded={refreshingIsNeeded}
                                    projects={sortedColumn.projects}
                                    title={sortedColumn.name}
                                    totalRows={sortedColumn.total_rows}
                                    nextPage={sortedColumn.nextPage}
                                    updatingStatusProjectId={
                                        updatingStatusProjectId
                                    }
                                />
                            );
                        })}
                    </DragDropContext>
                </PlaceholderColumn>
            </ColumnsWrapper>
            <ProjectPreviewModal.Container
                projectId={currentProjectId}
                setCurrentProjectId={setCurrentProjectId}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    branchOfficesDictionary: multiBranchesSelectors.getBranchesDictionary,
    funnelColumns: selectors.getFunnelColumnsData,
    isLoadingProjects: selectors.getIsFetchingFunnelColumns,
    nextPagesIds: selectors.getNextPagesIds,
    refreshingIsNeeded: selectors.getRefreshingIsNeeded,
    statusProject: projectSelectors.getStatusProjectDictionary,
    updatingStatusProjectId: selectors.getUpdatingStatusProjectId,
});

const mapDispatchToProps = (dispatch) => ({
    connectFunnelSocket: (userId, branchOfficesDictionary) =>
        dispatch(actions.connectFunnelSocket(userId, branchOfficesDictionary)),
    disconnectSocket: () => dispatch(funnelSocket.disconnect()),
    fetchFunnelProjects: (filterQuery) =>
        dispatch(actions.fetchFunnelProjects(filterQuery)),
    fetchNextPage: (status, nextProject, filterQuery) =>
        dispatch(actions.fetchNextPage(status, nextProject, filterQuery)),
    fetchStatusProject: () => dispatch(projectActions.fetchStatusProject()),
    handleOpenPreviewModal: () =>
        dispatch(quickViewProjectsActions.setIsOpenModal(true)),
    updateColumns: (fromColumnId, toColumnId, projectId, newIndex, oldIndex) =>
        dispatch(
            actions.updateColumns(
                fromColumnId,
                toColumnId,
                projectId,
                newIndex,
                oldIndex,
            ),
        ),
});

Container.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    canModify: PropTypes.bool,
    connectFunnelSocket: PropTypes.func,
    disconnectSocket: PropTypes.func,
    fetchFunnelProjects: PropTypes.func,
    fetchNextPage: PropTypes.func,
    fetchStatusProject: PropTypes.func,
    filterQuery: PropTypes.string,
    funnelColumns: PropTypes.object,
    handleOpenPreviewModal: PropTypes.func,
    isLoadingProjects: PropTypes.bool,
    isOnline: PropTypes.bool,
    nextPagesIds: PropTypes.object,
    refreshingIsNeeded: PropTypes.bool,
    statusProject: PropTypes.object,
    updateColumns: PropTypes.func,
    updatingStatusProjectId: PropTypes.string,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.FUNNEL_PROJECTS_PERMISSION),
    detectOnline(),
)(Container);
