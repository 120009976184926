import Geocode from 'react-geocode';

import { getIsoCodeCurrentLocale } from 'common/utils/helpers/multiregion';

import {
    FETCH_LOCATION,
    FETCH_LOCATION_SUCCESS,
    FETCH_LOCATION_ERROR,
} from '../actionTypes';
import { projectLocationActions } from '../reducer';

import savePosition from './savePosition';

const isValidZipCode = (zipCode) => {
    if (!zipCode || zipCode === 'None') return false;
    const numericPattern = /^\d+$/;
    return numericPattern.test(zipCode);
};

export default ({
        projectId,
        politicalDivision1 = '',
        politicalDivision2 = '',
        zipCode = null,
    }) =>
    (dispatch) => {
        Geocode.setApiKey(import.meta.env.VITE_GOOGLE_MAPS_KEY);
        Geocode.setLanguage(getIsoCodeCurrentLocale());
        Geocode.setRegion('mx');
        dispatch(projectLocationActions[FETCH_LOCATION]());
        const address = `${politicalDivision2?.name}, ${politicalDivision1?.name}`;
        const search = isValidZipCode(zipCode) ? zipCode : address;
        Geocode.fromAddress(search).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                const { formatted_address } = response.results[0];
                dispatch(
                    projectLocationActions[FETCH_LOCATION_SUCCESS](
                        response?.results[0],
                    ),
                );
                const locationData = {
                    city: politicalDivision2?.name,
                    cp: !zipCode ? parseInt(politicalDivision2?.code) : zipCode,
                    political_division1: politicalDivision1?.id,
                    position: { latitude: lat, longitude: lng },
                    title: formatted_address,
                };
                dispatch(savePosition(locationData, projectId));
            },
            (error) => {
                dispatch(projectLocationActions[FETCH_LOCATION_ERROR](error));
            },
        );
    };
