import API from '../..';
const ENTITY = 'integrations/embedded-proposals';

export const createItem = (data) =>
    API.post(
        `/api/v1/${ENTITY}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } },
    );
export const getItem = (id) => API.get(`/api/v1/${ENTITY}/${id}/`);
export const updateItem = (data, id) =>
    API.patch(
        `/api/v1/${ENTITY}/${id}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } },
    );
