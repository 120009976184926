import { create, update } from 'common/api/v2/costumerEnergyConsumption';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import projectConsumptionCard from '../../projectConsumptionCard';
import fetchLocationsByZipCode from '../../projectLocation/actions/fetchLocationsByZipCode';
import {
    SAVE_CONSUMPTIONS,
    SAVE_CONSUMPTIONS_FAILURE,
    SAVE_CONSUMPTIONS_SUCCESS,
} from '../actionTypes';
import { buildPayload } from '../helpers';
import { projectConsumptionModalActions } from '../reducer';
import * as selectors from '../selectors';

import setModalIsOpen from './setModalIsOpen';

export default (values = {}, onSuccessCallback = null) =>
    (dispatch, getState) => {
        const politicalDivisionsDic =
            selectors.getPoliticalDivisionsDictionary(getState());
        const politicalDivisions2Dic =
            selectors.getPoliticalDivisions2Dictionary(getState());
        const selectedProject = selectors.getSelectedProject(getState());

        const newValues = buildPayload({
            selectedProject,
            selectors,
            state: getState(),
            values,
        });

        dispatch(projectConsumptionModalActions[SAVE_CONSUMPTIONS]());

        const save = values.id
            ? () => update(newValues, selectedProject.id)
            : () => create(newValues, selectedProject.id);

        save()
            .then(({ data }) => {
                dispatch(
                    projectConsumptionModalActions[SAVE_CONSUMPTIONS_SUCCESS](
                        data,
                    ),
                );
                dispatch(setModalIsOpen(false));
                dispatch(
                    projectConsumptionCard.actions.fetchConsumptionEnergy(
                        selectedProject.id,
                    ),
                );
                if (!selectedProject?.has_location)
                    dispatch(
                        fetchLocationsByZipCode({
                            projectId: selectedProject.id,
                            politicalDivision1:
                                politicalDivisionsDic[
                                    values.political_division1
                                ],
                            politicalDivision2:
                                politicalDivisions2Dic[
                                    values.political_division2
                                ],
                            zipCode: values.zip_code,
                        }),
                    );
                showToast();
                if (onSuccessCallback) onSuccessCallback(selectedProject.id);
            })
            .catch((error) => {
                dispatch(
                    projectConsumptionModalActions[SAVE_CONSUMPTIONS_FAILURE](
                        error,
                    ),
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
