import React from 'react';

import { useTheme } from '@mui/material/styles';
import {
    KBarAnimator,
    KBarPortal,
    KBarPositioner,
    KBarProvider,
    KBarSearch,
} from 'kbar';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HandleActions from './HandleActions';
import RenderResults from './RenderResults';

const searchStyle = {
    background: '#fff',
    border: 'none',
    boxSizing: 'border-box',
    color: '#000',
    fontSize: '16px',
    outline: 'none',
    padding: '12px 16px',
    width: '100%',
};

const animatorStyle = {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '#000',
    color: '#000',
    maxWidth: '600px',
    overflow: 'hidden',
    width: '100%',
};

const CommandBar = ({ children }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <KBarProvider options={{ enableHistory: true }}>
            <HandleActions theme={theme} />

            {children}

            <KBarPortal>
                <KBarPositioner style={{ zIndex: theme.zIndex.modal + 1 }}>
                    <KBarAnimator
                        style={{
                            ...animatorStyle,
                            borderRadius: theme.shape.borderRadius,
                            color: theme.palette.text.primary,
                            background: theme.palette.background.default,
                            boxShadow: theme.shadows[24],
                        }}
                    >
                        <KBarSearch
                            defaultPlaceholder={t('Type or search a command')}
                            style={{
                                ...searchStyle,
                                color: theme.palette.text.primary,
                                background: theme.palette.background.default,
                            }}
                        />
                        <RenderResults />
                    </KBarAnimator>
                </KBarPositioner>
            </KBarPortal>
        </KBarProvider>
    );
};

CommandBar.propTypes = {
    children: PropTypes.node,
};

export default CommandBar;
