import API from '../../afterSalesAPI';
const ENTITY = 'projects';

export const createProject = (data) =>
    API.post(`/api/${ENTITY}`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const createProjectInfo = (sunwiseProject, data) =>
    API.post(`/api/${ENTITY}/${sunwiseProject}/projects`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const deleteProject = (sunwiseProject) =>
    API.delete(`/api/${ENTITY}/${sunwiseProject}`);

export const fillReads = (sunwiseProject, data) =>
    API.post(`/api/${ENTITY}/${sunwiseProject}/fill_reads`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const getAllReads = (data) =>
    API.post(`/api/${ENTITY}/reads`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const getAllSunwiseProjects = () =>
    API.get(`/api/${ENTITY}/all-account-sunwise-projects`);

export const getIndicatorsData = () => API.get(`/api/${ENTITY}/indicators`);

export const getLogs = (page, page_size) =>
    API.get(`/api/${ENTITY}/logs`, { params: { page, page_size } });

export const getProjectsBySunwiseProject = (sunwiseProject) =>
    API.get(`/api/${ENTITY}/${sunwiseProject}`);

export const getReadsByDate = (sunwiseProject, data) =>
    API.post(`/api/${ENTITY}/${sunwiseProject}/reads`, data, {
        headers: { 'Content-Type': 'application/json' },
    });

export const updateProjectInfo = (sunwiseProject, data) =>
    API.patch(`/api/${ENTITY}/${sunwiseProject}`, data, {
        headers: { 'Content-Type': 'application/json' },
    });
