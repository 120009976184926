import React from 'react';

import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import CertifiedRateSection from './CertifiedRateSection';
import CustomRateSection from './CustomRateSection';

const RateTaxesFields = ({
    canModifyIvaCFE,
    canModifyRatesAnualCFE,
    canModifyRatesDap,
    control,
    isLocked,
    nextRateData,
}) => {
    const [otherConcepts, taxesConcepts] = useWatch({
        control,
        name: ['other_concepts', 'taxes_concepts'],
    });

    if (isNull(nextRateData)) return null;
    if (nextRateData?.certified)
        return (
            <CertifiedRateSection
                canModifyRatesAnualCFE={canModifyRatesAnualCFE}
                canModifyRatesDap={canModifyRatesDap}
                canModifyTaxesRate={canModifyIvaCFE}
                control={control}
                isLocked={isLocked}
                otherConcepts={otherConcepts}
                rateName={nextRateData?.name}
                taxesConcepts={taxesConcepts}
            />
        );
    if (!nextRateData?.certified)
        return (
            <CustomRateSection
                canModifyRatesAnualCFE={canModifyRatesAnualCFE}
                canModifyTaxesRate={canModifyIvaCFE}
                control={control}
                isLocked={isLocked}
                otherConcepts={otherConcepts}
                taxesConcepts={taxesConcepts}
            />
        );
};

RateTaxesFields.propTypes = {
    canModifyIvaCFE: PropTypes.bool,
    canModifyRatesAnualCFE: PropTypes.bool,
    canModifyRatesDap: PropTypes.bool,
    control: PropTypes.object,
    isLocked: PropTypes.bool,
    nextRateData: PropTypes.object,
};

export default RateTaxesFields;
