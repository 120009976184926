import i18next from 'i18next';
import toNumber from 'lodash/toNumber';

import { updateFinancing } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { financingAndInsuranceActions } from '../reducer';

import closeModal from './closeModal';

export default (id, proposalId, values) => (dispatch) => {
    const newValues = {
        amount: values?.total,
        commercial_offer: proposalId,
        commission_credit: values.commission_credit,
        hitch_percentage: toNumber(values.hitch_percentage),
        hitch: toNumber(values.hitch),
        insurance: parseFloat(values.insurance),
        is_commission_percentage: values.is_commission_percentage,
        is_percentage: values.is_percentage,
        product_financial_plan: values.product_financial_plan,
        rate: parseFloat(values.rate),
        residual: parseFloat(values.residual),
        term: parseInt(values.term),
    };

    dispatch(financingAndInsuranceActions[SAVE]());

    updateFinancing(newValues, id)
        .then((response) => {
            dispatch(
                financingAndInsuranceActions[SAVE_SUCCESS](response.data.data),
            );
            dispatch(closeModal());
            showToast({
                type: 'success',
                body: i18next.t('{{name}} applied correctly', {
                    name: values.name,
                }),
            });
            dispatch(eventsAfterUpdate(['offer-details'], proposalId));
        })
        .catch((error) => {
            dispatch(
                financingAndInsuranceActions[SAVE_FAILURE](
                    error?.response?.data?.errors,
                ),
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
