import i18next from 'i18next';
import get from 'lodash/get';

import { INITIAL_VALUES } from '../actionTypes';
import { percentageToValue, valueToPercentage } from '../helpers';
import { financingAndInsuranceActions } from '../reducer';

import setIsOpenModal from './setIsOpenModal';

export default (values) => (dispatch) => {
    const parameters = get(values, 'parameters', {});
    let terms = [];

    if (parameters) {
        terms = parameters.terms.split(',').map((item) => ({
            label: `${item} ${i18next.t('Month', {
                count: parseInt(item),
            })}`,
            value: item,
        }));
    }

    const newValues = {
        amount: parameters.amount,
        commission_credit: parameters.commission_credit,
        file_percentage: parameters.file_percentage,
        hitch: percentageToValue(
            parameters.is_percentage,
            parameters.hitch,
            values.total,
        ),
        hitch_percentage: valueToPercentage(
            parameters.is_percentage,
            parameters.hitch,
            values.total,
        ),
        insurance: parameters.insurance,
        is_commission_percentage: parameters.is_commission_percentage,
        is_percentage: parameters.is_percentage,
        name: values.name,
        product_financial_plan: values.id,
        rate: parameters.rate,
        residual: parameters.residual,
        term: null,
        termsToSelect: terms,
    };

    if (values.hitch) newValues.hitch = values.hitch;
    if (values.hitch_percentage)
        newValues.hitch_percentage = values.hitch_percentage;
    if (values.residual) newValues.residual = values.residual;
    if (values.term) newValues.term = values.term?.toString();

    dispatch(financingAndInsuranceActions[INITIAL_VALUES](newValues));
    dispatch(setIsOpenModal(true));
};
