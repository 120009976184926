import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { normalizeHitchField, percentageToValue } from '../helpers';
import * as selectors from '../selectors';
import { financialProductFormValidate } from '../validate';

const FormContainer = ({
    currencyIso,
    currencySymbol,
    editionMode,
    handleClickCancel,
    initialValues,
    isLocked,
    isSaving,
    ownFinancialProduct,
    proposalId,
    save,
    setTitle,
    total,
    update,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        context: { initialValues, total },
        defaultValues: initialValues,
        resolver: yupResolver(financialProductFormValidate),
    });

    useEffect(() => {
        reset(initialValues);
        setTitle(initialValues.name);
    }, [initialValues]);

    const formValues = watch();

    const handleOnClickSave = (values) => {
        if (editionMode)
            update(ownFinancialProduct?.financingId, proposalId, {
                ...values,
                total,
            });
        else save(proposalId, { ...values, total });
    };

    if (!formValues.product_financial_plan) return null;

    const normalizeHitchPercentageField = ({ target: { value } }) => {
        setValue('hitch', percentageToValue(true, value, total));
    };

    return (
        <form>
            <Grid container>
                <Grid item xs={18} md={9}>
                    {formValues.is_percentage ? (
                        <ReactHookFormIntlNumberInput
                            append="%"
                            control={control}
                            disabled={isLocked}
                            fullWidth
                            label={t('Down payment')}
                            name="hitch_percentage"
                            onChange={normalizeHitchPercentageField}
                            step="any"
                        />
                    ) : (
                        <ReactHookFormIntlNumberInput
                            append={currencyIso}
                            control={control}
                            disabled={isLocked}
                            fullWidth
                            label={t('Down payment')}
                            onChange={({ target: { value } }) =>
                                normalizeHitchField(setValue, total)(value)
                            }
                            name="hitch"
                            prepend={currencySymbol}
                            step="any"
                        />
                    )}
                </Grid>
                <Grid item xs={18} md={9}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isLocked}
                        fullWidth
                        label={`${t('Term')} (${t('Month', {
                            count: 2,
                        })})`}
                        name="term"
                        options={[
                            {
                                label: t('Select a term'),
                                value: '',
                                disabled: true,
                            },
                            ...formValues.termsToSelect,
                        ]}
                    />
                </Grid>
            </Grid>
            {!formValues.file_percentage && (
                <>
                    <Grid container mt={1}>
                        <Grid item xs={18} md={9}>
                            <ReactHookFormIntlNumberInput
                                append={
                                    formValues.is_commission_percentage
                                        ? '%'
                                        : currencyIso
                                }
                                control={control}
                                disabled
                                fullWidth
                                label={t('Opening commission')}
                                name="commission_credit"
                                prepend={
                                    formValues.is_commission_percentage
                                        ? null
                                        : currencySymbol
                                }
                                step="any"
                            />
                        </Grid>
                        <Grid item xs={18} md={9}>
                            <ReactHookFormIntlNumberInput
                                append="%"
                                control={control}
                                disabled
                                fullWidth
                                label={t('Interest rate')}
                                name="rate"
                                step="any"
                            />
                        </Grid>
                    </Grid>
                    <Grid container mt={1}>
                        <Grid item xs={18} md={9}>
                            <ReactHookFormIntlNumberInput
                                append="%"
                                control={control}
                                disabled={isLocked}
                                fullWidth
                                label={t('Residual percentage')}
                                name="residual"
                                step="any"
                            />
                        </Grid>
                        <Grid item xs={18} lg={9}>
                            <ReactHookFormIntlNumberInput
                                append={currencyIso}
                                control={control}
                                disabled
                                fullWidth
                                label={t('Insurance')}
                                name="insurance"
                                prepend={currencySymbol}
                            />
                        </Grid>
                    </Grid>
                </>
            )}

            <Grid container mt={1}>
                <Grid
                    item
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'right' },
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={() => handleClickCancel()}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 2, md: 1 },
                            mr: { xs: 0, md: 2 },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isLocked}
                        onClick={handleSubmit(handleOnClickSave)}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 1, md: 2 },
                            mb: { xs: 2, md: 0 },
                        }}
                        type="button"
                        variant="outlined"
                    >
                        {isSaving ? t('Saving').concat('...') : t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    save: (id, values) => dispatch(actions.save(id, values)),
    update: (id, proposalId, values) =>
        dispatch(actions.updateOwnFinancial(id, proposalId, values)),
});

FormContainer.propTypes = {
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    editionMode: PropTypes.bool,
    handleClickCancel: PropTypes.func,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    ownFinancialProduct: PropTypes.object,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    setTitle: PropTypes.func,
    total: PropTypes.number,
    update: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
