import React from 'react';

import PropTypes from 'prop-types';

import funnelProjects from '../../funnelProjects';
import listProjects from '../../listProjects';

const ProjectsContainer = ({ filterQuery, viewMode }) => {
    if (viewMode === 'list')
        return <listProjects.Container filterQuery={filterQuery} />;
    return <funnelProjects.Container filterQuery={filterQuery} />;
};

ProjectsContainer.propTypes = {
    filterQuery: PropTypes.string,
    viewMode: PropTypes.string,
};

export default ProjectsContainer;
