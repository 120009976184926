import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import KeyIndicator from 'common/components/KeyIndicator';
import { numberFormat } from 'common/utils/helpers';

import AllianceItem from './AllianceItem';

const AllianceReviewRedGirasol = ({
    alliance,
    currencyLocale,
    financialProduct,
}) => {
    const { t } = useTranslation();
    const parameters = get(financialProduct, 'parameters', {});

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={9} md={4} lg={3}>
                <AllianceItem alliance={alliance} disabled />
            </Grid>
            <Grid item xs={18} lg={15}>
                <Grid container>
                    <Grid item xs={18} md={9} lg={4}>
                        <KeyIndicator
                            title={t('Interest rate')}
                            value={numberFormat(parameters.rate, {
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: '%',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={4}>
                        <KeyIndicator
                            title={t('Opening commission')}
                            value={numberFormat(parameters.commission_credit, {
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: '%',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={4}>
                        <KeyIndicator
                            title={t('Term')}
                            value={`${parameters.term} ${t('Month', {
                                count: 2,
                            })}`}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={4}>
                        <KeyIndicator
                            title={t('Down payment')}
                            value={numberFormat(parameters.hitch_percentage, {
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: '%',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    {parameters.residual > 0 && (
                        <Grid item xs={18} md={9} lg={4}>
                            <KeyIndicator
                                title={t('Residual')}
                                value={numberFormat(parameters.residual, {
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'decimal',
                                    unit: '%',
                                })}
                                widthIcon="20px"
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

AllianceReviewRedGirasol.propTypes = {
    alliance: PropTypes.object,
    currencyLocale: PropTypes.string,
    financialProduct: PropTypes.object,
};

export default AllianceReviewRedGirasol;
