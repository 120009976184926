import { asin, acos, sin, cos, mod, tan, round } from 'mathjs';

const MILISECONDS_IN_DAY = 24 * 60 * 60 * 1000;
const MILISECONDS_IN_HOUR = 60 * 60 * 1000;
const MILISECONDS_IN_MINUTE = 60 * 1000;

/******************************************************************************/

//The more accurate version. Returns a value in deg.
function equationOfTime2(dateTime, timeZone) {
    var dateBegining = new Date('January 01, 1900 00:00:00');
    var daysCount = (dateTime - dateBegining) / MILISECONDS_IN_DAY;
    var dateToday = new Date(dateTime);
    dateToday.setTime(
        dateToday.getTime() -
            dateToday.getHours() * MILISECONDS_IN_HOUR -
            dateToday.getMinutes() * MILISECONDS_IN_MINUTE -
            dateToday.getSeconds() * 1000,
    );
    dateTime = new Date(dateTime);
    var julianDay = daysCount + 2415020.48333 - timeZone / 24;

    var julianCentury = (julianDay - 2451545) / 36525;

    var geomMeanLongSun = mod(
        280.46646 + julianCentury * (36000.76983 + julianCentury * 0.0003032),
        360,
    );

    var geomMeanAnomalySun =
        357.52911 + julianCentury * (35999.05029 - 0.0001537 * julianCentury);

    var eccentricity =
        0.016708634 -
        julianCentury * (0.000042037 + 0.0000001267 * julianCentury);

    var meanObliquityofEcliptic =
        23 +
        (26 +
            (21.448 -
                julianCentury *
                    (46.815 +
                        julianCentury * (0.00059 - julianCentury * 0.001813))) /
                60) /
            60;

    var obliquityCorrection =
        meanObliquityofEcliptic +
        0.00256 * cos(degToRad(125.04 - 1934.136 * julianCentury));

    var y =
        tan(degToRad(obliquityCorrection / 2)) *
        tan(degToRad(obliquityCorrection / 2));

    var eot =
        4 *
        radToDeg(
            y * sin(2 * degToRad(geomMeanLongSun)) -
                2 * eccentricity * sin(degToRad(geomMeanAnomalySun)) +
                4 *
                    eccentricity *
                    y *
                    sin(degToRad(geomMeanAnomalySun)) *
                    cos(2 * degToRad(geomMeanLongSun)) -
                0.5 * y * y * sin(4 * degToRad(geomMeanLongSun)) -
                1.25 *
                    eccentricity *
                    eccentricity *
                    sin(2 * degToRad(geomMeanAnomalySun)),
        );

    return eot;
}

//Returns declination in deg
function getDeclination(dateTime, timeZone) {
    var dateBegining = new Date('January 01, 1900 00:00:00');
    var daysCount = (dateTime - dateBegining) / MILISECONDS_IN_DAY;
    var dateToday = new Date(dateTime);
    dateToday.setTime(
        dateToday.getTime() -
            dateToday.getHours() * MILISECONDS_IN_HOUR -
            dateToday.getMinutes() * MILISECONDS_IN_MINUTE -
            dateToday.getSeconds() * 1000,
    );
    dateTime = new Date(dateTime);
    var julianDay = daysCount + 2415020.48333 - timeZone / 24;

    var julianCentury = (julianDay - 2451545) / 36525;

    var meanObliquityofEcliptic =
        23 +
        (26 +
            (21.448 -
                julianCentury *
                    (46.815 +
                        julianCentury * (0.00059 - julianCentury * 0.001813))) /
                60) /
            60;

    var obliquityCorrection =
        meanObliquityofEcliptic +
        0.00256 * cos(degToRad(125.04 - 1934.136 * julianCentury));

    var geomMeanLongSun = mod(
        280.46646 + julianCentury * (36000.76983 + julianCentury * 0.0003032),
        360,
    );

    var geomMeanAnomalySun =
        357.52911 + julianCentury * (35999.05029 - 0.0001537 * julianCentury);

    var sunEquationOfCenter =
        sin(degToRad(geomMeanAnomalySun)) *
            (1.914602 - julianCentury * (0.004817 + 0.000014 * julianCentury)) +
        sin(degToRad(2 * geomMeanAnomalySun)) *
            (0.019993 - 0.000101 * julianCentury) +
        sin(degToRad(3 * geomMeanAnomalySun)) * 0.000289;

    var sunTrueLongitude = geomMeanLongSun + sunEquationOfCenter;

    var sunApparentLongitude =
        sunTrueLongitude -
        0.00569 -
        0.00478 * sin(degToRad(125.04 - 1934.136 * julianCentury));

    return radToDeg(
        asin(
            sin(degToRad(obliquityCorrection)) *
                sin(degToRad(sunApparentLongitude)),
        ),
    );
}

//Returns the the hour angle in rad
function getHourAngle(longitude, localTime, dateTime, timeZone) {
    var eot = equationOfTime2(dateTime, timeZone);
    var localStandardTimeMeridian = 15 * timeZone;
    var timeCorrectionFactor =
        (4 * (longitude - localStandardTimeMeridian) + eot) / 60;
    var localSolarTime = localTime + timeCorrectionFactor;
    var hourAngle = degToRad(15 * (localSolarTime - 12));
    if (hourAngle > Math.PI) {
        hourAngle -= 2 * Math.PI;
    } else if (hourAngle < -Math.PI) {
        hourAngle += 2 * Math.PI;
    }
    return hourAngle;
}

//Returns the real elevation in deg.
function getElevation(latitude, longitude, localTime, dateTime, timeZone) {
    var declination = getDeclination(dateTime, timeZone);
    var maxElevation = 90 + latitude - declination;
    if (maxElevation > 90) {
        maxElevation = 180 - maxElevation;
    }

    var hourAngle = getHourAngle(longitude, localTime, dateTime, timeZone);

    declination *= Math.PI / 180;
    latitude *= Math.PI / 180;

    var elevation =
        sin(declination) * sin(latitude) +
        cos(declination) * cos(latitude) * cos(hourAngle);
    elevation = asin(elevation);

    return (elevation * 180) / Math.PI;
}

//Returns the apparent elevation in deg.
function getRefractionCorrection(elevation) {
    var correctionFactor;
    if (elevation > 85) {
        return elevation;
    } else if (elevation > 5) {
        correctionFactor =
            (58.1 / tan(degToRad(elevation)) -
                0.07 / Math.pow(tan(degToRad(elevation)), 3) +
                0.000086 / Math.pow(tan(degToRad(elevation)), 5)) /
            3600;
    } else if (elevation > -0.575) {
        correctionFactor =
            (1735 -
                518.2 * elevation +
                103.4 * Math.pow(elevation, 2) -
                12.79 * Math.pow(elevation, 3) +
                0.711 * Math.pow(elevation, 4)) /
            3600;
    } else {
        correctionFactor = -20.774 / (3600 * tan(degToRad(elevation)));
    }

    return elevation + correctionFactor;
}

//Returns azimuth in deg.
function getAzimuth(latitude, longitude, localTime, dateTime, timeZone) {
    var declination = getDeclination(dateTime, timeZone);
    var hourAngle = getHourAngle(longitude, localTime, dateTime, timeZone);
    var solarZenithAngle = radToDeg(
        acos(
            sin(degToRad(latitude)) * sin(degToRad(declination)) +
                cos(degToRad(latitude)) *
                    cos(degToRad(declination)) *
                    cos(hourAngle),
        ),
    );

    if (hourAngle > 0) {
        return mod(
            radToDeg(
                acos(
                    (sin(degToRad(latitude)) * cos(degToRad(solarZenithAngle)) -
                        sin(degToRad(declination))) /
                        (cos(degToRad(latitude)) *
                            sin(degToRad(solarZenithAngle))),
                ),
            ) + 180,
            360,
        );
    } else {
        return mod(
            540 -
                radToDeg(
                    acos(
                        (sin(degToRad(latitude)) *
                            cos(degToRad(solarZenithAngle)) -
                            sin(degToRad(declination))) /
                            (cos(degToRad(latitude)) *
                                sin(degToRad(solarZenithAngle))),
                    ),
                ),
            360,
        );
    }
}

function degToRad(x) {
    return (x * Math.PI) / 180;
}

function radToDeg(x) {
    return (x * 180) / Math.PI;
}

/******************************************************************************/

export function getElevationAngle(latitude, longitude, dateTime, timeZone) {
    latitude = Number(latitude);
    longitude = Number(longitude);
    dateTime = Number(dateTime);
    timeZone = Number(timeZone);
    const dateRef = new Date(dateTime);
    dateRef.setTime(
        dateRef.getTime() -
            dateRef.getHours() * MILISECONDS_IN_HOUR -
            dateRef.getMinutes() * MILISECONDS_IN_MINUTE -
            dateRef.getSeconds() * 1000,
    );
    var time = (dateTime - dateRef) / MILISECONDS_IN_HOUR;

    var elevation = getElevation(latitude, longitude, time, dateTime, timeZone);

    return round(getRefractionCorrection(elevation), 2);
}

export function getAzimuthAngle(latitude, longitude, dateTime, timeZone) {
    latitude = Number(latitude);
    longitude = Number(longitude);
    dateTime = Number(dateTime);
    timeZone = Number(timeZone);
    const dateRef = new Date(dateTime);
    dateRef.setTime(
        dateRef.getTime() -
            dateRef.getHours() * MILISECONDS_IN_HOUR -
            dateRef.getMinutes() * MILISECONDS_IN_MINUTE -
            dateRef.getSeconds() * 1000,
    );
    var time = (dateTime - dateRef) / MILISECONDS_IN_HOUR;

    return round(getAzimuth(latitude, longitude, time, dateTime, timeZone), 2);
}

/******************************************************************************/

//two functions to check if DST is currently observed for user's location
Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1);
    var jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.isDstObserved = function () {
    return (this.stdTimezoneOffset() - this.getTimezoneOffset()) / 60;
};
