import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button, Grid, Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import * as credentialIntegrationsSelectors from '../../../credentialIntegrations/selectors';
import * as actions from '../../actions';
import { FILTERS_KEYS, RATE_ID_FILTERS } from '../../constants';
import { getFilteredSelectOptions } from '../../helpers';
import * as selectors from '../../selectors';

import StatusSelect from './StatusSelect';

const FiltersForm = ({
    agentsForSelect,
    changeFilters,
    contactFilters,
    handleClose,
    initialValues,
    isLoadingContacts,
    isLoadingPoliticalDivisions,
    isLoadingRates,
    politicalDivisionForSelect,
    projectFilters,
    providersForSelect,
    ratesDictionary,
}) => {
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [filteredProjectStatuses, setFilteredProjectStatuses] = useState([]);
    const [filteredRates, setFilteredRates] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
    });
    const { t } = useTranslation();
    const isMexicanAccount = getIsMexicanAccount();
    const politicalDivisionLabel = isMexicanAccount
        ? t('State')
        : t('Political division of order 1');

    const handleFilterProjectOptions = ({
        agentId,
        contactId,
        projectStatusId,
        rateId,
    }) => {
        const {
            contacts,
            projectStatuses,
            projects: newProjects,
            rates,
        } = getFilteredSelectOptions({
            agentId,
            contactId,
            contacts: contactFilters,
            projectStatusId,
            projects: projectFilters,
            rateId,
        });
        setFilteredContacts(contacts);
        setFilteredProjectStatuses(projectStatuses);
        setFilteredProjects(newProjects);
        setFilteredRates(rates);
    };

    useEffect(() => {
        if (initialValues) reset(initialValues);
    }, [initialValues]);

    useEffect(() => {
        handleFilterProjectOptions({
            agentId: initialValues?.[FILTERS_KEYS.AGENT],
            contactId: initialValues?.[FILTERS_KEYS.CONTACT_ID],
            projectStatusId: initialValues?.[FILTERS_KEYS.PROJECT_STATUS],
            rateId: initialValues?.[FILTERS_KEYS.RATE_ID],
        });
    }, [projectFilters, initialValues]);

    const onSubmit = (data) => {
        const rateId = data[FILTERS_KEYS.RATE_ID];
        const rateData = ratesDictionary[rateId];

        const newData = { ...data };

        if (!rateData?.is_certified) {
            delete newData[RATE_ID_FILTERS.CERTIFIED];
            newData[RATE_ID_FILTERS.NOT_CERTIFIED] = rateId;
        } else {
            delete newData[RATE_ID_FILTERS.NOT_CERTIFIED];
            newData[RATE_ID_FILTERS.CERTIFIED] = rateId;
        }

        changeFilters(newData);
        handleClose();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                <Grid item xs={18}>
                    <Typography variant="subtitle2">{t('Project')} </Typography>
                </Grid>

                <Grid item xs={18} md={9} lg={6} xl={4.5}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isLoadingContacts}
                        label={t('Agent')}
                        name={FILTERS_KEYS.AGENT}
                        onChange={(agentId) => {
                            handleFilterProjectOptions({ agentId });
                            setValue(FILTERS_KEYS.CONTACT_ID, null);
                            setValue(FILTERS_KEYS.PROJECT_STATUS, null);
                            setValue(FILTERS_KEYS.RATE_ID, null);
                            setValue(FILTERS_KEYS.PROJECT_ID, null);
                        }}
                        options={agentsForSelect}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md={9} lg={6} xl={4.5}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isLoadingContacts}
                        label={t('Contact')}
                        name={FILTERS_KEYS.CONTACT_ID}
                        onChange={(contact) => {
                            handleFilterProjectOptions({
                                agentId: getValues(FILTERS_KEYS.AGENT),
                                contactId: contact,
                            });
                            setValue(FILTERS_KEYS.PROJECT_STATUS, null);
                            setValue(FILTERS_KEYS.PROJECT_ID, null);
                            setValue(FILTERS_KEYS.RATE_ID, null);
                        }}
                        options={filteredContacts}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md={9} lg={6} xl={4.5}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isLoadingContacts}
                        label={t('Project status')}
                        name={FILTERS_KEYS.PROJECT_STATUS}
                        onChange={(projectStatus) => {
                            handleFilterProjectOptions({
                                agentId: getValues(FILTERS_KEYS.AGENT),
                                contactId: getValues(FILTERS_KEYS.CONTACT_ID),
                                projectStatusId: projectStatus,
                            });
                            setValue(FILTERS_KEYS.PROJECT_ID, null);
                            setValue(FILTERS_KEYS.RATE_ID, null);
                        }}
                        options={filteredProjectStatuses}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md={9} lg={6} xl={4.5}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isLoadingRates}
                        label={t('Rate')}
                        name={FILTERS_KEYS.RATE_ID}
                        onChange={(rate) => {
                            handleFilterProjectOptions({
                                agentId: getValues(FILTERS_KEYS.AGENT),
                                contactId: getValues(FILTERS_KEYS.CONTACT_ID),
                                projectStatusId: getValues(
                                    FILTERS_KEYS.PROJECT_STATUS,
                                ),
                                rateId: rate,
                            });
                            setValue(FILTERS_KEYS.PROJECT_ID, null);
                        }}
                        options={filteredRates}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={18} md={9} lg={6} xl={4.5}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isLoadingContacts}
                        label={t('Project')}
                        name={FILTERS_KEYS.PROJECT_ID}
                        options={filteredProjects}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={18}>
                    <Typography variant="subtitle2">{t('Location')}</Typography>
                </Grid>
                <Grid item xs={18} md={9} lg={6} xl={4.5}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isLoadingPoliticalDivisions}
                        label={politicalDivisionLabel}
                        name={FILTERS_KEYS.POLITICAL_DIVISION}
                        options={politicalDivisionForSelect}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={18} md={9} lg={6} xl={4.5}>
                    <ReactHookFormInput
                        control={control}
                        label={t('Zip code')}
                        name={FILTERS_KEYS.ZIP_CODE}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={18}>
                    <Typography variant="subtitle2">
                        {t('System', { count: 2 })}
                    </Typography>
                </Grid>
                <Grid item xs={18} md={9} lg={6} xl={4.5}>
                    <StatusSelect
                        control={control}
                        label={t('Status')}
                        name={FILTERS_KEYS.STATUS}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={18} md={9} lg={6} xl={4.5}>
                    <ReactHookFormSelect2
                        control={control}
                        label={t('Provider')}
                        name={FILTERS_KEYS.PROVIDER}
                        options={[{ label: '', options: providersForSelect }]}
                        variant="standard"
                    />
                </Grid>
            </Grid>

            <BottomActions>
                <Box
                    display="flex"
                    flexDirection={{ md: 'row', xs: 'column' }}
                    gap={1}
                    justifyContent={{ md: 'right', xs: 'center' }}
                    mt={2}
                    width="100%"
                >
                    <Button
                        color="secondary"
                        onClick={handleClose}
                        sx={{
                            order: { md: 1, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        sx={{
                            order: { md: 1, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="outlined"
                        type="submit"
                    >
                        {t('Apply')}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    agentsForSelect: selectors.getAgentsForSelect,
    contactFilters: selectors.getContacts,
    initialValues: selectors.getInitialValuesFilters,
    isLoadingContacts: selectors.getContactsIsLoading,
    isLoadingPoliticalDivisions: selectors.getPoliticalDivisionIsLoading,
    isLoadingRates: selectors.getRatesIsLoading,
    politicalDivisionForSelect: selectors.getPoliticalDivisionForSelect,
    projectFilters: selectors.getProjects,
    providersForSelect: credentialIntegrationsSelectors.getProvidersForSelect,
    ratesDictionary: selectors.getRatesDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    changeFilters: (filters) => dispatch(actions.changeFilters(filters)),
});

FiltersForm.propTypes = {
    agentsForSelect: PropTypes.array,
    changeFilters: PropTypes.func,
    contactFilters: PropTypes.array,
    handleClose: PropTypes.func,
    initialValues: PropTypes.object,
    isLoadingContacts: PropTypes.bool,
    isLoadingPoliticalDivisions: PropTypes.bool,
    isLoadingRates: PropTypes.bool,
    politicalDivisionForSelect: PropTypes.array,
    projectFilters: PropTypes.array,
    providersForSelect: PropTypes.array,
    ratesDictionary: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersForm);
