import get from 'lodash/get';
import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import { percentageToValue, valueToPercentage } from './helpers';

export const ciBancoFormValidate = (_, props) => {
    const {
        getMinValueText,
        getMaxValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    const { parameters, total } = props.initialValues;
    const isPercentage = get(parameters, 'is_percentage', false);
    const hitch = get(parameters, 'hitch', false);
    const minHitch = percentageToValue(isPercentage, hitch, total);
    const minHitchPercentage = valueToPercentage(isPercentage, hitch, total);
    return object().shape({
        term: string().when(['step', 'is_serfimex'], {
            is: (step, isSerfimex) => step === 2 && isSerfimex === false,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        hitch_percentage: number().when(['step', 'is_percentage'], {
            is: (step, is_percentage) => step === 2 && is_percentage === 'true',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(
                        minHitchPercentage,
                        getMinValueText(minHitchPercentage),
                    )
                    .max(100, getMaxValueText(100))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        hitch: number().when(['step', 'is_percentage'], {
            is: (step, is_percentage) =>
                step === 2 && is_percentage === 'false',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(minHitch, getMinValueText(minHitch))
                    .max(total, getMaxValueText(total))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
    });
};
