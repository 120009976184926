import API from '../../.';
const ENTITY = 'customize-rates';

export const getItemV2 = (id) => API.get(`/api/v2/${ENTITY}/${id}`);
export const filterItemsV2 = (params) =>
    API.get(`/api/v2/${ENTITY}/${params ? `?${params}` : ''}`);
export const createItemV2 = (data) =>
    API.post(
        `/api/v2/${ENTITY}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } },
    );
export const updateItemV2 = (id, data) =>
    API.put(
        `/api/v2/${ENTITY}/${id}`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } },
    );
export const setEnableItemV2 = (id, isEnable) =>
    API.put(`/api/v2/${ENTITY}-activate/${id}`, { is_enable: isEnable });
