import { lastDayOfMonth, differenceInDays } from 'date-fns';
import i18next from 'i18next';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import slice from 'lodash/slice';

import { MONTHS } from 'common/constants';
import { formatDate } from 'common/utils/dates';
import { getMonthLabel, numberFormat } from 'common/utils/helpers';
import { getArrayHours, getDaysNames } from 'common/utils/helpersChart';

import { POLYGON_SEGMENT } from './constants';

const BASE_LOSS = 25;

const getDatesOfDayOfWeek = (year, month, dayOfWeek) => {
    const initialDate = new Date(year, month, 1);
    const datesOfDayOfWeek = [];

    while (initialDate.getDay() !== dayOfWeek) {
        initialDate.setDate(initialDate.getDate() + 1);
    }

    while (initialDate.getMonth() === month) {
        const nextDate = new Date(initialDate.getTime());
        datesOfDayOfWeek.push(nextDate.getDate());
        initialDate.setDate(initialDate.getDate() + 7);
    }

    return datesOfDayOfWeek;
};

const getDaysInMonth = (month, year) => {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
};

const getFirstLastDayOfMonth = (month) => {
    const firstDateOfMonth = new Date(new Date().getFullYear(), month, 1);
    const lastDateOfMonth = lastDayOfMonth(firstDateOfMonth);
    return { initialDate: firstDateOfMonth, finalDate: lastDateOfMonth };
};

const getQuantityOfDaysInMonth = (month) => {
    const { initialDate, finalDate } = getFirstLastDayOfMonth(month);
    return differenceInDays(finalDate, initialDate) + 1;
};

const getHourlyValuesBetweenDates = (values, initialDate, finalDate) => {
    const firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);
    const daysElapsedFromFirstDayOfTheYearToInitialDate = differenceInDays(
        initialDate,
        firstDayOfTheYear,
    );
    const daysElapsedFromFirstDayOfTheYearToFinalDate =
        differenceInDays(finalDate, firstDayOfTheYear) + 1;

    const _values = slice(
        values,
        daysElapsedFromFirstDayOfTheYearToInitialDate * 24,
        daysElapsedFromFirstDayOfTheYearToFinalDate * 24,
    );

    return _values;
};

const convertHourlyToDaily = (values) => {
    if (!Array.isArray(values)) return [];
    const numberOfDays = values.length / 24;
    let result = new Array(numberOfDays).fill(0);
    let currentHour = 0;
    let currentDay = 0;

    for (let i = 0; i < values.length; i++) {
        if (currentHour > 23) {
            currentDay++;
            currentHour = 0;
        }
        result[currentDay] += values[i];
        currentHour++;
    }

    return result;
};

const convertHourlyToMonthly = (values) => {
    let result = new Array(12).fill(0);

    for (let m = 0; m < 12; m++) {
        const { initialDate, finalDate } = getFirstLastDayOfMonth(m);

        const hourlyValuesBetweenDates = getHourlyValuesBetweenDates(
            values,
            initialDate,
            finalDate,
        );

        const totalMonthly = hourlyValuesBetweenDates.reduce(
            (acc, curr) => acc + curr,
            0,
        );

        result[m] = totalMonthly;
    }

    return result;
};

const convertHourlyToWeekly = (values, month, week) => {
    const _values = convertHourlyToDaily(values);
    let result = new Array(7).fill(0);

    for (let i = 0; i < 7; i++) {
        const dataIndex = getDatesOfDayOfWeek(
            new Date().getFullYear(),
            month,
            i,
        )[week];
        result[i] = _values[dataIndex - 1];
    }

    result.push(result.shift());

    return result;
};

const getHourlyDataFromDate = (values, month, week, day) => {
    if (!Array.isArray(values)) return [];

    const dataIndex = getDatesOfDayOfWeek(new Date().getFullYear(), month, day)[
        week
    ];

    let result = new Array();

    const start = (dataIndex - 1) * 24;
    const end = start + 23;

    for (let i = start; i < end; i++) {
        result.push(values[i]);
    }

    return result;
};

const getAnnualSerie = (segments) => {
    let data = new Array(12).fill(0);

    for (let d = 0; d < segments.length; d++) {
        const item = segments[d];
        const _item = item[Object.keys(item)[0]];

        const values = convertHourlyToMonthly(_item.ac_hourly);

        for (let i = 0; i < values.length; i++) {
            data[i] += values[i];
        }
    }

    return data;
};

const getMonthlySerie = (controlValues, segments) => {
    const { month } = controlValues;
    let data = new Array(getQuantityOfDaysInMonth(month)).fill(0);

    for (let d = 0; d < segments.length; d++) {
        const item = segments[d];
        const _item = item[Object.keys(item)[0]];

        const { initialDate, finalDate } = getFirstLastDayOfMonth(month);

        const hourlyValuesBetweenDates = getHourlyValuesBetweenDates(
            _item.ac_hourly,
            initialDate,
            finalDate,
        );

        const values = convertHourlyToDaily(hourlyValuesBetweenDates);

        for (let i = 0; i < values.length; i++) {
            data[i] += values[i];
        }
    }

    return data;
};

const getWeeklySerie = (controlValues, segments) => {
    const { month, week } = controlValues;
    let data = new Array(7).fill(0);

    for (let d = 0; d < segments.length; d++) {
        const item = segments[d];
        const _item = item[Object.keys(item)[0]];

        const { initialDate, finalDate } = getFirstLastDayOfMonth(month);

        const hourlyValuesBetweenDates = getHourlyValuesBetweenDates(
            _item.ac_hourly,
            initialDate,
            finalDate,
        );

        const values = convertHourlyToWeekly(
            hourlyValuesBetweenDates,
            month,
            week,
        );

        for (let i = 0; i < values.length; i++) {
            data[i] += values[i];
        }
    }

    return data;
};

const getDailySerie = (controlValues, segments) => {
    const { month, week, day } = controlValues;
    let data = new Array(24).fill(0);

    for (let d = 0; d < segments.length; d++) {
        const item = segments[d];
        const _item = item[Object.keys(item)[0]];

        const { initialDate, finalDate } = getFirstLastDayOfMonth(month);

        const hourlyValuesBetweenDates = getHourlyValuesBetweenDates(
            _item.ac_hourly,
            initialDate,
            finalDate,
        );

        const values = getHourlyDataFromDate(
            hourlyValuesBetweenDates,
            month,
            week,
            day,
        );

        for (let i = 0; i < values.length; i++) {
            data[i] += values[i];
        }
    }

    return data;
};

const applyLosses = (data, losses = BASE_LOSS) => {
    let result = [];
    if (!Array.isArray(data) || data.length === 0) return result;
    const parsedLoss = parseFloat(losses);
    if (parsedLoss === BASE_LOSS) return data;

    const adjustLoss = 1 - parsedLoss / 100;

    for (let i = 0; i < data.length; i++) {
        result[i] = (data[i] / (100 - BASE_LOSS)) * adjustLoss * 100;
    }

    return result;
};

export const getCategories = (controlValues) => {
    const { period } = controlValues;

    if (period === 0) return MONTHS.map((month) => getMonthLabel(month));
    if (period === 1) {
        const { month } = controlValues;
        return getDaysInMonth(month, new Date().getFullYear()).map((date) =>
            formatDate(date, 'dd (iii)'),
        );
    }
    if (period === 2) return getDaysNames();
    if (period === 3) return getArrayHours();
};

export const getSeries = (controlValues, irradiationData) => {
    const { segment, period, losses } = controlValues;
    const segments =
        segment === 'all'
            ? irradiationData
            : irradiationData.filter((item) => {
                  return Object.keys(item)[0] === segment;
              });
    let data = [];

    if (period === 0) data = getAnnualSerie(segments);
    if (period === 1) data = getMonthlySerie(controlValues, segments);
    if (period === 2) data = getWeeklySerie(controlValues, segments);
    if (period === 3) data = getDailySerie(controlValues, segments);

    return [{ name: 'AC', data: applyLosses(data, losses) }];
};

export const hasMissingIrradiation = (irradiationData, segments) => {
    if (!irradiationData.length) return false;
    if (!segments.length) return false;

    const polygonSegments = segments.filter(
        (segment) => segment.type === POLYGON_SEGMENT,
    );

    if (!polygonSegments.length) return false;

    if (irradiationData.length !== polygonSegments.length) return true;

    const idsInIrradiation = [];
    const idsInSegments = [];

    for (let i = 0; i < irradiationData.length; i++) {
        const item = irradiationData[i];
        idsInIrradiation.push(Object.keys(item)[0]);
    }

    for (let j = 0; j < polygonSegments.length; j++) {
        const item = polygonSegments[j];
        idsInSegments.push(item.id);
    }

    return differenceWith(idsInIrradiation, idsInSegments, isEqual) > 0;
};

export const getKeyIndicatorTitle = (value) => {
    switch (value) {
        case 1:
            return i18next.t('Monthly generation');
        case 2:
            return i18next.t('Weekly generation');
        case 3:
            return i18next.t('Daily generation');
        default:
            return i18next.t('Yearly generation');
    }
};

export const getSystemSize = (offerPanels) => {
    if (!offerPanels?.length) return 0;

    const watts = offerPanels.reduce(
        (acc, curr) => acc + (curr.assigned * curr.watts || 0),
        0,
    );

    return watts / 1000;
};

export const getAnnualSunHours = ({ offerPanels, irradiationData, losses }) => {
    if (!irradiationData?.length || !offerPanels?.length) return 0;

    const totalGeneration = irradiationData.reduce((acc, curr) => {
        const item = curr[Object.keys(curr)[0]];
        return acc + item.ac_annual;
    }, 0);

    const systemSize = getSystemSize(offerPanels);
    const days = 365;

    const parsedLoss = parseFloat(losses);
    const adjustLoss = 1 - parsedLoss / 100;

    const sunHours = totalGeneration / (systemSize * days) || 0;
    const baseLossFactor = 1 - BASE_LOSS / 100;

    return (sunHours * adjustLoss) / baseLossFactor;
};

export const getSunHoursIndicatorValue = ({
    countryCurrencyLocale,
    irradiationData,
    losses,
    offerPanels,
}) => {
    const sunHours = getAnnualSunHours({
        irradiationData,
        losses,
        offerPanels,
    });

    const sunHoursFormatted = numberFormat(sunHours || 0, {
        decimals: 2,
        locale: countryCurrencyLocale,
        style: 'decimal',
        unit: '',
    });

    const kWhKWp = numberFormat(sunHours * 365 || 0, {
        decimals: 2,
        locale: countryCurrencyLocale,
        style: 'decimal',
        unit: '',
    });

    return `${kWhKWp} - ${sunHoursFormatted}`;
};
