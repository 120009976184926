import get from 'lodash/get';

import { parseExponentialNumber } from 'common/utils/helpers';

import { INITIAL_VALUES } from '../actionTypes';
import { proposalGeneratorInverterTableActions } from '../reducer';

export default (offerInverters, offerPanelExpansion) => (dispatch) => {
    const items = offerInverters.map((product) => {
        return {
            cost: product.cost,
            discount: product.discount,
            discount_type: product.discount_type,
            final_cost: product.final_cost,
            id: product.id,
            is_compatible: product.is_compatible,
            item: get(product, 'inverter_branch_office.id', null),
            locked: product.locked,
            margin: parseExponentialNumber(product.margin),
            max_panels: product.max_panels,
            min_panels: product.min_panels,
            oversize: product.oversize,
            percentage_discount: product.percentage_discount,
            quantity: product.quantity,
            selectedProduct: get(product, 'inverter_branch_office.id', null),
            temp_cost: product.cost,
            temp_discount: product.discount,
            temp_margin: product.margin,
            temp_quantity: product.quantity,
            unit_price: product.unit_price,
        };
    });

    dispatch(
        proposalGeneratorInverterTableActions[INITIAL_VALUES]({
            expansion: offerPanelExpansion,
            items,
        }),
    );
};
