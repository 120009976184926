import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_OFFER_COMPLEMENTS,
    FETCH_OFFER_COMPLEMENTS_FAILURE,
    FETCH_OFFER_COMPLEMENTS_SUCCESS,
    FETCH_PLAN,
    FETCH_PLAN_FAILURE,
    FETCH_PLAN_SUCCESS,
    INITIAL_VALUES,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_IS_OPEN_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: {
        apply_desired_power_factor: false,
        bag_years: 2,
        considerations: '',
        currency_abbreviation: 'USD',
        currency: null,
        custom_offer_template: null,
        dap_value: 0,
        degradation_percentage: 0,
        deprecated: false,
        desired_power_factor: 90,
        discount_per_unit: 0,
        discount_rate: 10,
        discount: 0,
        energy_export_price: 0,
        id: null,
        increase_percentage: 0,
        isr_percentage: 0,
        isr: false,
        opex_percentage_increase: 0,
        opex_periodicity: 0,
        opex: 0,
        other_concepts: [],
        price_total: 0,
        price_watt: 0,
        proposal_plan: null,
        tax_rate_percentage: 16,
        taxes_concepts: [],
        type_change_offer: 0,
        type_change: 0,
        type_discount: 0,
        zero_export: false,
    },
    fetchPlan: { data: {}, errors: [], isFetching: false },
    isOpenModal: false,
    offerComplements: {
        data: {},
        errors: [],
        isFetching: false,
    },
    save: {
        data: [],
        errors: [],
        isSaving: false,
    },
};

const proposalGeneratorConfigModalSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_OFFER_COMPLEMENTS]: (state) => {
            state.offerComplements = {
                ...state.offerComplements,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_OFFER_COMPLEMENTS_FAILURE]: (state, action) => {
            state.offerComplements = {
                ...state.offerComplements,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_OFFER_COMPLEMENTS_SUCCESS]: (state, action) => {
            state.offerComplements = {
                ...state.offerComplements,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PLAN]: (state) => {
            state.fetchPlan = {
                ...state.fetchPlan,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PLAN_FAILURE]: (state, action) => {
            state.fetchPlan = {
                ...state.fetchPlan,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PLAN_SUCCESS]: (state, action) => {
            state.fetchPlan = {
                ...state.fetchPlan,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIAL_VALUES]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const proposalGeneratorConfigModalActions =
    proposalGeneratorConfigModalSlice.actions;

export default proposalGeneratorConfigModalSlice.reducer;
