import React, { useContext, useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, UiDialog as Dialog } from 'sunwise-ui';

import HeaderContainer from 'common/components/HeaderContainer';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import * as productDatasheetsActions from 'common/modules/productDatasheets/actions';
import { LoadingContext } from 'common/utils/contexts';

import * as multiCurrencySelectors from '../multiCurrency/selectors';
import * as onboardingWizardActions from '../onboardingWizard/actions';
import * as onboardingWizardSelectors from '../onboardingWizard/selectors';

import * as actions from './actions';
import {
    ListItems,
    ListItemsBatteries,
    MarginForm,
    ProductForm,
} from './components';
import * as selectors from './selectors';

const Container = ({
    addProductToBranches,
    batteryBrands,
    branchOfficesDictionary,
    branchSelected,
    canDelete,
    canModify,
    canView,
    currencies,
    downloadFile,
    fetchBatteryBrands,
    filterItems,
    filterItemsParent,
    handleClickBulkItems,
    handleClickCreate,
    handleClickDownloadDatasheet,
    handleClickUpdate,
    handleClickUpdateMargin,
    handleCloseModal,
    handleCloseModalMargin,
    handleCloseModalProduct,
    handleSaveRow,
    initialize,
    initializeForm,
    isDownloading,
    isFetchingBatteryBrands,
    isFetchingItems,
    isFetchingItemsParent,
    isFetchingTableSettings,
    isInitializing,
    isOpenModal,
    isOpenModalMargin,
    isOpenModalProduct,
    isSavingProductAddedToBranches,
    isUploading,
    items,
    itemsParent,
    paginationItems,
    paginationItemsParent,
    prepareUpload,
    tableSettings,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
    });

    useEffect(() => {
        initialize();
        filterItems(filterData);
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDownloading)
            loadingContext.openLoading(t('Downloading document').concat('...'));
        else loadingContext.closeLoading();
    }, [isDownloading]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isUploading)
            loadingContext.openLoading(t('Loading document').concat('...'));
        else loadingContext.closeLoading();
    }, [isUploading]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingProductAddedToBranches)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...'),
            );
        else loadingContext.closeLoading();
    }, [isSavingProductAddedToBranches]);

    const handlePrepareUpload = (file) => prepareUpload(file, filterData);

    const addButton = () => (
        <Button
            endIcon={<AddIcon sx={{ color: 'primary.main' }} />}
            onClick={handleClickCreate}
            variant="outlined"
            visible={canModify}
        >
            {t('Add storage')}
        </Button>
    );

    return (
        <>
            <HeaderContainer>
                <Grid item xs={18} textAlign="right">
                    {addButton()}
                </Grid>
            </HeaderContainer>

            {canView && (
                <ListItems
                    canDelete={canDelete}
                    canModify={canModify}
                    downloadFile={(downloadType) =>
                        downloadFile(filterData, downloadType)
                    }
                    filterData={filterData}
                    filterItems={filterItems}
                    handleClickBulkItems={handleClickBulkItems}
                    handleClickDownloadDatasheet={handleClickDownloadDatasheet}
                    handleClickUpdate={handleClickUpdate}
                    handleClickUpdateMargin={handleClickUpdateMargin}
                    handleSaveRow={handleSaveRow}
                    isFetching={
                        isInitializing ||
                        isFetchingItems ||
                        isFetchingTableSettings
                    }
                    isOpenModalMargin={isOpenModalMargin}
                    items={items}
                    loadingContext={loadingContext}
                    paginationData={paginationItems}
                    prepareUploadFile={handlePrepareUpload}
                    setFilterData={setFilterData}
                    tableSettings={tableSettings}
                />
            )}

            <Box sx={{ textAlign: 'right' }}>{addButton()}</Box>

            <Dialog
                onClose={() => handleCloseModalMargin()}
                open={isOpenModalMargin}
                size="sm"
                title={t('Exchange price by margin')}
            >
                <MarginForm canModify={canModify} filterData={filterData} />
            </Dialog>

            <Dialog
                onClose={() => handleCloseModalProduct(filterData)}
                open={isOpenModalProduct}
                title={t('Update storage')}
            >
                <ProductForm
                    canDelete={canDelete}
                    canModify={canModify}
                    currencies={currencies}
                    filterData={filterData}
                    handleClickBulkItems={handleClickBulkItems}
                    handleCloseModalProduct={handleCloseModalProduct}
                    initializeForm={initializeForm}
                />
            </Dialog>

            <Dialog
                onClose={() => handleCloseModal(filterData)}
                onEnter={() => {
                    filterItemsParent();
                    fetchBatteryBrands();
                }}
                open={isOpenModal}
                title={t('Add storage')}
            >
                <ListItemsBatteries
                    addProductToBranches={addProductToBranches}
                    batteryBrands={batteryBrands}
                    branchOfficesDictionary={branchOfficesDictionary}
                    branchSelected={branchSelected}
                    canModify={canModify}
                    data={itemsParent}
                    filterItemsParent={filterItemsParent}
                    isFetching={
                        isFetchingItemsParent || isFetchingBatteryBrands
                    }
                    pagination={paginationItemsParent}
                />
            </Dialog>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    batteryBrands: onboardingWizardSelectors.getBatteryBrandsData,
    branchOfficesDictionary: multiBranchesSelectors.getBranchesDictionary,
    currencies: multiCurrencySelectors.getDataFetchCurrencies,
    isDownloading: selectors.getIsDownloading,
    isFetchingBatteryBrands:
        onboardingWizardSelectors.getIsFetchingBatteryBrands,
    isFetchingItems: selectors.getIsFetchingItems,
    isFetchingItemsParent: selectors.getIsFetchingItemsParent,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isInitializing: selectors.getIsInitializing,
    isOpenModal: selectors.getIsOpenModal,
    isOpenModalMargin: selectors.getIsOpenModalMargin,
    isOpenModalProduct: selectors.getIsOpenModalProduct,
    isSavingProductAddedToBranches: selectors.getIsSavingProductAddedToBranches,
    isUploading: selectors.getIsUploading,
    items: selectors.getFetchItemsData,
    itemsParent: selectors.getFetchItemsParentData,
    paginationItems: selectors.getFetchItemsPagination,
    paginationItemsParent: selectors.getFetchItemsParentPagination,
    tableSettings: customColumnsSelectors.getTableSettingsData,
});

const mapDispatchToProps = (dispatch) => ({
    addProductToBranches: (id, branches, successCallback) =>
        dispatch(actions.addProductToBranches(id, branches, successCallback)),
    downloadFile: (filterData, type) =>
        dispatch(actions.downloadFile(filterData, type)),
    fetchBatteryBrands: () =>
        dispatch(onboardingWizardActions.fetchBatteryBrands()),
    filterItems: (filterData) => dispatch(actions.filterItems(filterData)),
    filterItemsParent: (filterData) =>
        dispatch(actions.filterItemsParent(filterData)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData, callback)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handleClickDownloadDatasheet: (datasheet, openLoading, closeLoading) =>
        dispatch(
            productDatasheetsActions.downloadDatasheet(
                datasheet,
                openLoading,
                closeLoading,
            ),
        ),
    handleClickUpdate: (item, disabled) => dispatch(actions.prepareUpdate(item, disabled)),
    handleClickUpdateMargin: (ids, callback) =>
        dispatch(actions.prepareUpdateMargin(ids, callback)),
    handleCloseModal: (filterData) => dispatch(actions.closeModal(filterData)),
    handleCloseModalMargin: () => dispatch(actions.closeModalMargin()),
    handleCloseModalProduct: (filterData) =>
        dispatch(actions.closeModalProduct(filterData)),
    handleSaveRow: (item) => dispatch(actions.updateProduct(item)),
    initialize: () => dispatch(actions.initialFetching()),
    initializeForm: (values) => dispatch(actions.initializeForm(values)),
    prepareUpload: (file, filterData) =>
        dispatch(actions.prepareUpload(file, filterData)),
});

Container.propTypes = {
    addProductToBranches: PropTypes.func,
    batteryBrands: PropTypes.array,
    branchOfficesDictionary: PropTypes.object,
    branchSelected: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    currencies: PropTypes.array,
    downloadFile: PropTypes.func,
    fetchBatteryBrands: PropTypes.func,
    filterItems: PropTypes.func,
    filterItemsParent: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickDownloadDatasheet: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleClickUpdateMargin: PropTypes.func,
    handleCloseModal: PropTypes.func,
    handleCloseModalMargin: PropTypes.func,
    handleCloseModalProduct: PropTypes.func,
    handleSaveRow: PropTypes.func,
    initialize: PropTypes.func,
    initializeForm: PropTypes.func,
    isDownloading: PropTypes.bool,
    isFetchingBatteryBrands: PropTypes.bool,
    isFetchingItems: PropTypes.bool,
    isFetchingItemsParent: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isOpenModalMargin: PropTypes.bool,
    isOpenModalProduct: PropTypes.bool,
    isSavingProductAddedToBranches: PropTypes.bool,
    isUploading: PropTypes.bool,
    items: PropTypes.array,
    itemsParent: PropTypes.array,
    paginationItems: PropTypes.object,
    paginationItemsParent: PropTypes.object,
    prepareUpload: PropTypes.func,
    tableSettings: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.ENERGY_BACKUP_PERMISSION),
)(Container);
