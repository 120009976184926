import API from '../..';
const ENTITY = 'inverters-companies';

export const addProductToBranches = (data) =>
    API.post(`/api/v1/${ENTITY}/`, { ...data });
export const exportFile = ({
    key,
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    personalized,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/export-upload/`, {
        params: personalized
            ? {
                  key,
                  order_by: orderBy,
                  page: page,
                  per_page: perPage,
                  personalized,
                  search: searchText,
                  ...(searchText ? { search: searchText } : {}),
                  sort: sortBy,
                  status_flag: status,
              }
            : {},
        headers: { 'Content-Type': 'text/csv' },
    });
export const filterItems = ({
    orderBy = null,
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/filter/${searchText}`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
        },
    });
export const getItems = () => API.get(`/api/v1/${ENTITY}/`);
export const importFile = (data) =>
    API.post(`/api/v1/${ENTITY}/export-upload/`, { ...data });
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
