import React from 'react';

import PropTypes from 'prop-types';

import { ReactHookFormFroala } from 'common/components/form/bootstrap';

const TabConditions = ({ control, isDisabled }) => (
    <ReactHookFormFroala control={control} disabled={isDisabled} name="considerations" />
);

TabConditions.propTypes = { control: PropTypes.object, isDisabled: PropTypes.bool };

export default TabConditions;
