import TagManager from 'react-gtm-module';

export const initIntercom = () => {
    const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
    if (!INTERCOM_APP_ID) return;
    (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            var d = document;
            var i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            var l = function () {
                var s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + INTERCOM_APP_ID;
                var x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
        }
    })();
};

export const initTagManager = () => {
    const GOOGLE_TAG_MANAGER_ID = import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID;
    if (!GOOGLE_TAG_MANAGER_ID) return;
    TagManager.initialize({
        gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
    });
};

export const initClarity = () => {
    const CLARITY_ID = import.meta.env.VITE_CLARITY_ID;
    if (!CLARITY_ID) return;
    const c = window;
    const l = document;
    const a = 'clarity';
    const r = 'script';
    c[a] =
        c[a] ||
        function () {
            (c[a].q = c[a].q || []).push(arguments);
        };
    let t = l.createElement(r);
    t.async = 1;
    t.src = 'https://www.clarity.ms/tag/' + CLARITY_ID;
    let y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
};

export const initAllThirdParty = () => {
    initTagManager();
    initIntercom();
    initClarity();
};
