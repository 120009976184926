import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useFieldArray, useWatch } from 'react-hook-form';
import { Box, Tab, Tabs, TabPanel } from 'sunwise-ui';

import { useDebounce } from 'common/hooks';

import { DEBOUNCE_TIME } from '../../../constants';

import Period from './Period';

const FieldArrayPeriods = ({
    control,
    getValues,
    isAnnualType,
    isHourlyRate,
    name,
    paymentType,
    setValue,
    showMode,
    trigger,
}) => {
    const [selectedTab, setSelectedTab] = useState(null);
    const { fields } = useFieldArray({ control, name });
    const periods = useWatch({ control, name });
    const debouncedPeriods = useDebounce(periods, DEBOUNCE_TIME);

    useEffect(() => {
        setSelectedTab(debouncedPeriods?.[0]?.label);
        if (trigger) trigger(name);
    }, [debouncedPeriods]);

    if (isAnnualType)
        return (
            <Period
                control={control}
                isAnnualType
                isHourlyRate={isHourlyRate}
                getValues={getValues}
                key={paymentType}
                name={`${name}.0`}
                paymentType={paymentType}
                setValue={setValue}
                showMode={showMode}
            />
        );

    const handleOnChangeTab = (_, newValue) => setSelectedTab(newValue);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleOnChangeTab}>
                    {fields.map((_, index) => {
                        const period = get(debouncedPeriods, `[${index}]`, {});
                        return (
                            <Tab
                                key={`settings-period-tab-${period.label}`}
                                id={`settings-period-tab-${period.label}`}
                                label={period.label}
                                value={period.label}
                            />
                        );
                    })}
                </Tabs>
            </Box>

            {fields.map((item, index) => {
                const period = get(debouncedPeriods, `[${index}]`, {});
                return (
                    <TabPanel
                        key={`settings-period-tab-${item.id}`}
                        selectedTab={selectedTab}
                        value={period.label}
                    >
                        <Period
                            control={control}
                            getValues={getValues}
                            isHourlyRate={isHourlyRate}
                            key={item.id}
                            name={`${name}.${index}`}
                            paymentType={paymentType}
                            setValue={setValue}
                            showMode={showMode}
                        />
                    </TabPanel>
                );
            })}
        </>
    );
};

FieldArrayPeriods.propTypes = {
    control: PropTypes.object,
    getValues: PropTypes.func,
    isAnnualType: PropTypes.bool,
    isHourlyRate: PropTypes.bool,
    name: PropTypes.string,
    paymentType: PropTypes.string,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    trigger: PropTypes.func,
};

export default FieldArrayPeriods;
