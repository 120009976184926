import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Element } from 'react-scroll';
import { TextField, InputAdornment } from 'sunwise-ui';

import {
    getCountryCurrencyDecimalSeparator,
    getCountryCurrencyThousandSeparator,
} from 'common/utils/helpers/session';
const handleValue = (e) => {
    let newValue = '';
    if (e.target.value) {
        const [integerPart, decimalPart] = e.target.value.split(
            getCountryCurrencyDecimalSeparator(),
        );
        let cleanedIntegerPart = 0;
        if (integerPart)
            cleanedIntegerPart = integerPart.replaceAll(
                getCountryCurrencyThousandSeparator(),
                '',
            );
        let cleanedNumber = `${parseInt(cleanedIntegerPart)}`;
        if (decimalPart) cleanedNumber = `${cleanedNumber}.${decimalPart}`;
        newValue = cleanedNumber;
    }
    return newValue;
};
const ReactHookFormIntlNumberInput = (props) => {
    const {
        allowDecimals,
        allowNegativeValue,
        append,
        autoFocus,
        control,
        dataIntercomTarget,
        decimalsLimit,
        disabled,
        fullWidth,
        label,
        max,
        min,
        name,
        onBlur,
        onChange,
        onFocus,
        onKeyDown,
        onKeyUp,
        onPaste,
        placeholder,
        prepend,
        readOnly,
        sx,
        variant,
    } = props;

    const getIsAllowedValue = ({ floatValue }) => {
        const _min = parseFloat(min);
        const _max = parseFloat(max);
        if (
            (_min && !isNaN(_min) && floatValue < _min) ||
            (_max && !isNaN(_max) && floatValue > _max)
        )
            return false;
        return true;
    };
    const onBlurAction = (event, onBlur) => {
        const value = handleValue(event);
        if (onBlur) onBlur({ event, value });
    };
    const onFocusAction = (event, onFocus) => {
        const value = handleValue(event);
        if (onFocus) onFocus({ event, value });
    };
    const onKeyDownAction = (event, onKeyDown) => {
        const value = handleValue(event);
        if (onKeyDown && value) onKeyDown({ event, value });
    };
    const onKeyUpAction = (event, onKeyUp) => {
        const value = handleValue(event);
        if (onKeyUp && value) onKeyUp({ event, value });
    };
    const onPasteAction = (event, onPaste) => {
        const value = handleValue(event);
        if (onPaste) onPaste(event, value);
    };
    return (
        <>
            {name && <Element name={`position-${name}`} />}
            <Controller
                control={control}
                name={name}
                render={({ field, fieldState: { error } }) => (
                    <NumberFormat
                        allowNegative={allowNegativeValue}
                        autoFocus={autoFocus}
                        customInput={TextField}
                        data-intercom-target={dataIntercomTarget}
                        decimalScale={!allowDecimals ? 0 : decimalsLimit}
                        decimalSeparator={getCountryCurrencyDecimalSeparator()}
                        disabled={disabled}
                        error={Boolean(error)}
                        fullWidth={fullWidth}
                        helperText={error?.message}
                        isAllowed={getIsAllowedValue}
                        InputProps={{
                            endAdornment: append && (
                                <InputAdornment position="end">
                                    {append}
                                </InputAdornment>
                            ),
                            readOnly: readOnly,
                            startAdornment: prepend && (
                                <InputAdornment position="start">
                                    {prepend}
                                </InputAdornment>
                            ),
                            inputRef: field.ref,
                        }}
                        isNumericString
                        label={label}
                        name={field.name}
                        onBlur={(e) => onBlurAction(e, onBlur)}
                        onChange={(e) => {
                            const newValue = handleValue(e);
                            if (onChange)
                                onChange({
                                    ...e,
                                    target: {
                                        ...e.target,
                                        value: newValue,
                                    },
                                });
                        }}
                        onFocus={(e) => onFocusAction(e, onFocus)}
                        onKeyDown={(e) => onKeyDownAction(e, onKeyDown)}
                        onKeyUp={(e) => onKeyUpAction(e, onKeyUp)}
                        onPaste={(e) => onPasteAction(e, onPaste)}
                        onValueChange={(target) => {
                            field.onChange({ target });
                        }}
                        placeholder={placeholder}
                        sx={sx}
                        thousandSeparator={getCountryCurrencyThousandSeparator()}
                        value={field.value}
                        variant={variant}
                    />
                )}
            />
        </>
    );
};
ReactHookFormIntlNumberInput.defaultProps = {
    allowDecimals: true,
    allowNegativeValue: true,
    autoFocus: false,
    decimalsLimit: 16,
    step: 1,
};
ReactHookFormIntlNumberInput.propTypes = {
    allowDecimals: PropTypes.bool,
    allowNegativeValue: PropTypes.bool,
    append: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoFocus: PropTypes.bool,
    control: PropTypes.object,
    dataIntercomTarget: PropTypes.string,
    decimalsLimit: PropTypes.number,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onPaste: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    readOnly: PropTypes.bool,
    variant: PropTypes.string,
    sx: PropTypes.object,
};
export default ReactHookFormIntlNumberInput;
