import API from '../..';
const ENTITY = 'storage-offer';

export const create = (commercialOfferId) =>
    API.post(`/api/v2/put-post-${ENTITY}/${commercialOfferId}`);
export const deleteStorageOffer = (commercialOfferId) =>
    API.delete(`/api/v2/put-post-${ENTITY}/${commercialOfferId}`);
export const getStorageOffer = (commercialOfferId) =>
    API.get(`/api/v2/${ENTITY}/${commercialOfferId}`);
export const save = (commercialOfferId, values) =>
    API.put(`/api/v2/put-post-${ENTITY}/${commercialOfferId}`, { ...values });
