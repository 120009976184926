import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
    FETCH_ACCESSORIES,
    FETCH_ACCESSORIES_FAILURE,
    FETCH_ACCESSORIES_SUCCESS,
    FETCH_ADDITIONALS,
    FETCH_ADDITIONALS_FAILURE,
    FETCH_ADDITIONALS_SUCCESS,
    FETCH_BATTERIES,
    FETCH_BATTERIES_FAILURE,
    FETCH_BATTERIES_SUCCESS,
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
    FETCH_INVERTERS,
    FETCH_INVERTERS_FAILURE,
    FETCH_INVERTERS_SUCCESS,
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
    FETCH_PANELS,
    FETCH_PANELS_FAILURE,
    FETCH_PANELS_SUCCESS,
    FETCH_PLAN,
    FETCH_PLAN_SUCCESS,
    FETCH_PLAN_FAILURE,
    FETCH_STRUCTURES,
    FETCH_STRUCTURES_FAILURE,
    FETCH_STRUCTURES_SUCCESS,
    FETCH_WORKFORCE,
    FETCH_WORKFORCE_FAILURE,
    FETCH_WORKFORCE_SUCCESS,
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
    INITIALIZE_FORM,
    OPEN_MODAL_PRODUCT,
    RESET_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_IS_NEW_RECORD,
    UPDATE_ITEMS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    bulkUpdateItems: {
        errors: null,
        isSaving: false,
    },
    deleteItem: {
        errors: null,
        isDeleting: false,
    },
    fetchAccessories: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchAdditionals: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchBatteries: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchInverters: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchItems: {
        data: {
            data: [],
            pagination: null,
        },
        errors: null,
        isFetching: false,
    },
    fetchPanels: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchPlan: {
        data: {},
        errors: null,
        isFetching: false,
    },
    fetchStructures: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchTemplates: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchWorkforce: {
        data: [],
        errors: null,
        isFetching: false,
    },
    initialValues: {
        accessories: [{ item: '' }],
        additionals: [{ item: '' }],
        battery: '',
        branch_office: '',
        considerations: '',
        disabled: false,
        external_custom_template_id: '',
        id: null,
        installation_type: [],
        inverter_brand: '',
        name: null,
        opex_periodicity: 0,
        panel: '',
        saving_percentage: 0,
        structures: [{ item: '' }],
        validity: 30,
        workforce_and_electric_materials: [{ item: '' }],
    },
    isInitializing: false,
    isNewRecord: true,
    isOpenModalProduct: false,
    saveItem: {
        errors: null,
        isSaving: false,
    },
};

const planConfigurationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isSaving: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isSaving: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isSaving: false,
            };
        },
        [DELETE_ITEM]: (state) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_ITEM_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_ITEM_SUCCESS]: (state) => {
            state.deleteItem = {
                ...state.deleteItem,
                isDeleting: false,
            };
        },
        [FETCH_ACCESSORIES]: (state) => {
            state.fetchAccessories = {
                ...state.fetchAccessories,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ACCESSORIES_FAILURE]: (state, action) => {
            state.fetchAccessories = {
                ...state.fetchAccessories,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ACCESSORIES_SUCCESS]: (state, action) => {
            state.fetchAccessories = {
                ...state.fetchAccessories,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ADDITIONALS]: (state) => {
            state.fetchAdditionals = {
                ...state.fetchAdditionals,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ADDITIONALS_FAILURE]: (state, action) => {
            state.fetchAdditionals = {
                ...state.fetchAdditionals,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ADDITIONALS_SUCCESS]: (state, action) => {
            state.fetchAdditionals = {
                ...state.fetchAdditionals,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BATTERIES]: (state) => {
            state.fetchBatteries = {
                ...state.fetchBatteries,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_BATTERIES_FAILURE]: (state, action) => {
            state.fetchBatteries = {
                ...state.fetchBatteries,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_BATTERIES_SUCCESS]: (state, action) => {
            state.fetchBatteries = {
                ...state.fetchBatteries,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES]: (state) => {
            state.fetchTemplates = {
                ...state.fetchTemplates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_TEMPLATES_FAILURE]: (state, action) => {
            state.fetchTemplates = {
                ...state.fetchTemplates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES_SUCCESS]: (state, action) => {
            state.fetchTemplates = {
                ...state.fetchTemplates,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTERS]: (state) => {
            state.fetchInverters = {
                ...state.fetchInverters,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_INVERTERS_FAILURE]: (state, action) => {
            state.fetchInverters = {
                ...state.fetchInverters,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTERS_SUCCESS]: (state, action) => {
            state.fetchInverters = {
                ...state.fetchInverters,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS]: (state) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ITEMS_FAILURE]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANELS]: (state) => {
            state.fetchPanels = {
                ...state.fetchPanels,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PANELS_FAILURE]: (state, action) => {
            state.fetchPanels = {
                ...state.fetchPanels,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANELS_SUCCESS]: (state, action) => {
            state.fetchPanels = {
                ...state.fetchPanels,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PLAN]: (state) => {
            state.fetchPlan = {
                ...state.fetchPlan,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PLAN_FAILURE]: (state, action) => {
            state.fetchPlan = {
                ...state.fetchPlan,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PLAN_SUCCESS]: (state, action) => {
            state.fetchPlan = {
                ...state.fetchPlan,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_STRUCTURES]: (state) => {
            state.fetchStructures = {
                ...state.fetchStructures,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_STRUCTURES_FAILURE]: (state, action) => {
            state.fetchStructures = {
                ...state.fetchStructures,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_STRUCTURES_SUCCESS]: (state, action) => {
            state.fetchStructures = {
                ...state.fetchStructures,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_WORKFORCE]: (state) => {
            state.fetchWorkforce = {
                ...state.fetchWorkforce,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_WORKFORCE_FAILURE]: (state, action) => {
            state.fetchWorkforce = {
                ...state.fetchWorkforce,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_WORKFORCE_SUCCESS]: (state, action) => {
            state.fetchWorkforce = {
                ...state.fetchWorkforce,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE]: (state) => {
            state.isInitializing = true;
        },
        [INITIALIZE_FAILURE]: (state) => {
            state.isInitializing = false;
        },
        [INITIALIZE_SUCCESS]: (state) => {
            state.isInitializing = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [OPEN_MODAL_PRODUCT]: (state, action) => {
            state.isOpenModalProduct = action.payload;
        },
        [RESET_FORM]: (state) => {
            const {
                fetchAccesories,
                fetchAdditionals,
                fetchTemplates,
                fetchInverters,
                fetchPanels,
                fetchStructures,
                fetchWorkforce,
                initialValues,
            } = INITIAL_STATE;

            return {
                ...state,
                fetchAccesories,
                fetchAdditionals,
                fetchTemplates,
                fetchInverters,
                fetchPanels,
                fetchStructures,
                fetchWorkforce,
                initialValues,
            };
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state) => {
            state.saveItem = {
                ...state.saveItem,
                isSaving: false,
            };
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [UPDATE_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems.data.data = action.payload;
        },
    },
});

export const planConfigurationActions = planConfigurationSlice.actions;

export default planConfigurationSlice.reducer;
