import React from 'react';

import i18next from 'i18next';
import { Switch } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { validateAccessWithPermissions } from 'common/utils/helpers/permissionsV2';

export const initialActions = ({
    preferences,
    redirect,
    saveConfig,
    setPreferences,
    setTheme,
    theme,
}) => {
    const mode = theme?.palette?.mode || 'light';

    const handleOnToggleMenuPreference = () => {
        setPreferences({
            ...preferences,
            displaySubmenuOnHover: !preferences.displaySubmenuOnHover,
        });
    };

    const handleOnSwitchTheme = () => {
        const theme = mode === 'dark' ? 'light' : 'dark';
        setTheme(theme);
        saveConfig({ mode: theme });
    };

    const actions = [
        {
            id: 'contact-action',
            name: i18next.t('Contact', { count: 2 }),
            perform: () => redirect('/contacts'),
            requiredPermissions: [PERMISSION_LIST.CONTACTS_PERMISSION],
            section: i18next.t('Contact', { count: 2 }),
            shortcut: ['g', 'c'],
        },
        {
            id: 'contact-followups-action',
            name: i18next.t('Follow-up', { count: 2 }),
            perform: () => redirect('/follow-ups'),
            requiredPermissions: [PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION],
            section: i18next.t('Contact', { count: 2 }),
            shortcut: ['g', 'f'],
        },
        {
            id: 'sales-dashboards-action',
            name: 'Dashboard',
            perform: () => redirect('/dashboard'),
            requiredPermissions: [PERMISSION_LIST.DASHBOARD_PERMISSION],
            section: i18next.t('Sales'),
            shortcut: ['g', 'd'],
        },
        {
            id: 'sales-project-funnel-action',
            name: i18next.t('Project funnel'),
            perform: () => redirect('/project-funnel'),
            requiredPermissions: [PERMISSION_LIST.FUNNEL_PROJECTS_PERMISSION],
            section: i18next.t('Sales'),
            shortcut: ['g', 'k'],
        },
        {
            id: 'sales-financing-funnel-Action',
            name: i18next.t('Financing funnel'),
            perform: () => redirect('/financing-funnel'),
            requiredPermissions: [PERMISSION_LIST.FUNNEL_FINANCING_PERMISSION],
            section: i18next.t('Sales'),
        },
        {
            id: 'sales-proposals-action',
            name: i18next.t('Proposal', { count: 2 }),
            perform: () => redirect('/proposals'),
            requiredPermissions: [PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION],
            section: i18next.t('Sales'),
            shortcut: ['g', 'p'],
        },
        {
            id: 'aftersales-monitoring-dashboard-action',
            name: i18next.t('Monitoring'),
            perform: () => redirect('/monitoring-dashboard'),
            requiredPermissions: [
                PERMISSION_LIST.MONITORING_DASHBOARD_PERMISSION,
            ],
            section: 'After sales',
            shortcut: ['g', 'm'],
        },
        {
            id: 'analytics-action',
            name: 'Analytics',
            requiredPermissions: [
                PERMISSION_LIST.ANALYTICS_PERMISSION,
                PERMISSION_LIST.CFE_PERMISSION,
                PERMISSION_LIST.CRE_PERMISSION,
            ],
            section: 'Analytics',
        },
        {
            id: 'analytics-sunwise-action',
            name: 'Sunwise',
            parent: 'analytics-action',
            perform: () => redirect('/analytics'),
            requiredPermissions: [PERMISSION_LIST.ANALYTICS_PERMISSION],
            section: 'Analytics',
        },
        {
            id: 'analytics-CFEA-action',
            name: 'CFE',
            parent: 'analytics-action',
            perform: () => redirect('/analytics-cfe'),
            requiredPermissions: [PERMISSION_LIST.CFE_PERMISSION],
            section: 'Analytics',
        },
        {
            id: 'analytics-CRE-action',
            name: 'CRE',
            parent: 'analytics-action',
            perform: () => redirect('/analytics-cre'),
            requiredPermissions: [PERMISSION_LIST.CRE_PERMISSION],
            section: 'Analytics',
        },
        {
            id: 'parameters-solar-generation-action',
            name: i18next.t('Solar generation'),
            perform: () => redirect('/solar-generation'),
            requiredPermissions: [PERMISSION_LIST.SOLAR_GENERATION_PERMISSION],
            section: i18next.t('Parameter', { count: 2 }),
        },
        {
            id: 'parameters-equipment-compatibility-action',
            name: i18next.t('Electrical compatibility'),
            perform: () => redirect('/equipment-compatibility'),
            requiredPermissions: [
                PERMISSION_LIST.EQUIPMENT_COMPATIBILITY_PERMISSION,
            ],
            section: i18next.t('Parameter', { count: 2 }),
        },
        {
            id: 'parameters-rates-action',
            name: i18next.t('Rate', { count: 2 }),
            perform: () => redirect('/rates'),
            requiredPermissions: [PERMISSION_LIST.CERTIFIED_RATES_PERMISSION],
            section: i18next.t('Parameter', { count: 2 }),
        },
        {
            id: 'parameters-taxes-action',
            name: i18next.t('Tax', { count: 2 }),
            perform: () => redirect('/taxes'),
            requiredPermissions: [PERMISSION_LIST.TAXES_PERMISSION],
            section: i18next.t('Parameter', { count: 2 }),
        },
        {
            id: 'parameters-currencies-action',
            name: i18next.t('Currency', { count: 2 }),
            perform: () => redirect('/currencies'),
            requiredPermissions: [PERMISSION_LIST.CURRENCIES_PERMISSION],
            section: i18next.t('Parameter', { count: 2 }),
        },
        {
            id: 'parameters-consumption-profiles-action',
            name: i18next.t('Consumption profile', { count: 2 }),
            perform: () => redirect('/consumption-profiles'),
            requiredPermissions: [
                PERMISSION_LIST.SELFCONSUMPTION_ADMIN_PROFILES_PERMISSION,
            ],
            section: i18next.t('Parameter', { count: 2 }),
        },
        {
            id: 'catalog-products-action',
            name: i18next.t('Product', { count: 2 }),
            requiredPermissions: [
                PERMISSION_LIST.ACCESORIES_PERMISSION,
                PERMISSION_LIST.ADITIONALS_PERMISSION,
                PERMISSION_LIST.ALLIANCES_PERMISSION,
                PERMISSION_LIST.ENERGY_BACKUP_PERMISSION,
                PERMISSION_LIST.FINANCIAL_PRODUCTS_PERMISSION,
                PERMISSION_LIST.INVERTERS_PERMISSION,
                PERMISSION_LIST.PANELS_PERMISSION,
                PERMISSION_LIST.PLANS_PERMISSION,
                PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
                PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION,
                PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
                PERMISSION_LIST.STRUCTURES_PERMISSION,
                PERMISSION_LIST.WORKFORCE_PERMISSION,
                PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION,
            ],
            section: i18next.t('Catalog', { count: 2 }),
        },
        {
            id: 'catalog-panels-Action',
            name: i18next.t('Panel', { count: 2 }),
            parent: 'catalog-products-action',
            perform: () => redirect('/products/panels'),
            requiredPermissions: [PERMISSION_LIST.PANELS_PERMISSION],
            section: i18next.t('Product', { count: 2 }),
        },
        {
            id: 'catalogI-inverters-action',
            name: i18next.t('Inverter', { count: 2 }),
            parent: 'catalog-products-action',
            perform: () => redirect('/products/inverters'),
            requiredPermissions: [PERMISSION_LIST.INVERTERS_PERMISSION],
            section: i18next.t('Product', { count: 2 }),
        },
        {
            id: 'catalog-storage-action',
            name: i18next.t('Storage'),
            parent: 'catalog-products-action',
            perform: () => redirect('/products/storage'),
            requiredPermissions: [PERMISSION_LIST.ENERGY_BACKUP_PERMISSION],
            section: i18next.t('Product', { count: 2 }),
        },
        {
            id: 'catalog-accessories-action',
            name: i18next.t('Accessory', { count: 2 }),
            parent: 'catalog-products-action',
            perform: () => redirect('/products/accessories'),
            requiredPermissions: [PERMISSION_LIST.ACCESORIES_PERMISSION],
            section: i18next.t('Product', { count: 2 }),
        },
        {
            id: 'catalog-structures-action',
            name: i18next.t('Structure', { count: 2 }),
            parent: 'catalog-products-action',
            perform: () => redirect('/products/structures'),
            requiredPermissions: [PERMISSION_LIST.STRUCTURES_PERMISSION],
            section: i18next.t('Product', { count: 2 }),
        },
        {
            id: 'catalog-workforce-action',
            name: i18next.t('Workforce'),
            parent: 'catalog-products-action',
            perform: () => redirect('/products/workforce'),
            requiredPermissions: [PERMISSION_LIST.WORKFORCE_PERMISSION],
            section: i18next.t('Product', { count: 2 }),
        },
        {
            id: 'catalog-additionals-action',
            name: i18next.t('Additional', { count: 2 }),
            parent: 'catalog-products-action',
            perform: () => redirect('/products/additionals'),
            requiredPermissions: [PERMISSION_LIST.ADITIONALS_PERMISSION],
            section: i18next.t('Product', { count: 2 }),
        },
        {
            id: 'catalog-plan-action',
            name: i18next.t('Plan', { count: 2 }),
            perform: () => redirect('/plan-configuration'),
            requiredPermissions: [PERMISSION_LIST.PLANS_PERMISSION],
            section: i18next.t('Catalog', { count: 2 }),
        },
        {
            id: 'catalog-template-action',
            name: i18next.t('Template', { count: 2 }),
            requiredPermissions: [
                PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
                PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION,
                PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
                PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION,
            ],
            section: i18next.t('Catalog', { count: 2 }),
        },
        {
            id: 'catalog-template-proposal-action',
            name: i18next.t('Proposal', { count: 2 }),
            parent: 'catalog-template-action',
            perform: () => redirect('/template-library/proposals'),
            requiredPermissions: [
                PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
            ],
            section: i18next.t('Template', { count: 2 }),
        },
        {
            id: 'catalog-template-summaries-action',
            name: i18next.t('Summary', { count: 2 }),
            parent: 'catalog-template-action',
            perform: () => redirect('/template-library/summaries'),
            requiredPermissions: [PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION],
            section: i18next.t('Template', { count: 2 }),
        },
        {
            id: 'catalog-template-smart-documents-action',
            name: i18next.t('Smart document', { count: 2 }),
            parent: 'catalog-template-action',
            perform: () => redirect('/template-library/smart-documents'),
            requiredPermissions: [
                PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
            ],
            section: i18next.t('Template', { count: 2 }),
        },
        {
            id: 'catalog-template-reports-action',
            name: i18next.t('Report', { count: 2 }),
            parent: 'catalog-template-action',
            perform: () => redirect('/template-library/reports'),
            requiredPermissions: [PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION],
            section: i18next.t('Template', { count: 2 }),
        },
        {
            id: 'catalog-alliances-action',
            name: i18next.t('Alliance', { count: 2 }),
            perform: () => redirect('/alliances'),
            requiredPermissions: [PERMISSION_LIST.ALLIANCES_PERMISSION],
            section: i18next.t('Catalog', { count: 2 }),
        },
        {
            id: 'catalog-financial-entity-action',
            name: i18next.t('Financial entity', { count: 2 }),
            perform: () => redirect('/financial-products'),
            requiredPermissions: [
                PERMISSION_LIST.FINANCIAL_PRODUCTS_PERMISSION,
            ],
            section: i18next.t('Catalog', { count: 2 }),
        },
        {
            id: 'integrations-web-quoter-action',
            name: i18next.t('Web quoter'),
            perform: () => redirect('/web-quoter'),
            requiredPermissions: [PERMISSION_LIST.EMBEDDED_PROPOSAL_PERMISSION],
            section: i18next.t('Integration', { count: 2 }),
        },
        {
            id: 'integrations-monitoring-credentials-action',
            name: i18next.t('Monitoring credentials'),
            perform: () => redirect('/monitoring-credentials'),
            requiredPermissions: [PERMISSION_LIST.REPORTS_PERMISSION],
            section: i18next.t('Integration', { count: 2 }),
        },
        {
            id: 'integrations-suppliers-action',
            name: i18next.t('Supplier', { count: 2 }),
            perform: () => redirect('/suppliers'),
            requiredPermissions: [PERMISSION_LIST.SUPPLIERS_PERMISSION],
            section: i18next.t('Integration', { count: 2 }),
        },
        {
            id: 'integrations-zapier-action',
            name: 'Zapier',
            perform: () => redirect('/zapier'),
            requiredPermissions: [PERMISSION_LIST.ZAPIER_PERMISSION],
            section: i18next.t('Integration', { count: 2 }),
        },
        {
            id: 'configuration-general-action',
            name: i18next.t('General information'),
            perform: () => redirect('/company'),
            requiredPermissions: [
                PERMISSION_LIST.GENERAL_COMPANY_INFO_PERMISSION,
            ],
            section: i18next.t('Setting'),
        },
        {
            id: 'configuration-branch-offices-action',
            name: i18next.t('Branch office', { count: 2 }),
            perform: () => redirect('/branch-offices'),
            requiredPermissions: [PERMISSION_LIST.BRANCH_OFFICES_PERMISSION],
            section: i18next.t('Setting'),
        },
        {
            id: 'configuration-branding-offices-action',
            name: i18next.t('Branding'),
            perform: () => redirect('/branding'),
            requiredPermissions: [PERMISSION_LIST.BRANDING_PERMISSION],
            section: i18next.t('Setting'),
        },
        {
            id: 'configuration-roles-action',
            name: i18next.t('Role', { count: 2 }),
            perform: () => redirect('/roles'),
            requiredPermissions: [PERMISSION_LIST.ROLES_PERMISSION],
            section: i18next.t('Setting'),
        },
        {
            id: 'configuration-users-action',
            name: i18next.t('User', { count: 2 }),
            perform: () => redirect('/users'),
            requiredPermissions: [PERMISSION_LIST.COMPANY_USERS_PERMISSION],
            section: i18next.t('Setting'),
        },
        {
            id: 'configuration-lists-action',
            name: i18next.t('List', { count: 2 }),
            perform: () => redirect('/lists'),
            requiredPermissions: [PERMISSION_LIST.LIST_SETTINGS_PERMISSION],
            section: i18next.t('Setting'),
        },
        {
            id: 'profile-general-action',
            name: i18next.t('General information'),
            perform: () => redirect('/profile/general'),
            section: i18next.t('Profile'),
        },
        {
            id: 'profile-subscription-action',
            name: i18next.t('Subscription'),
            perform: () => redirect('/profile/subscription'),
            requiredPermissions: [PERMISSION_LIST.SUBSCRIPTION_PERMISSION],
            section: i18next.t('Profile'),
        },
        {
            icon: (
                <Switch
                    checked={mode === 'dark'}
                    onChange={() => {
                        handleOnSwitchTheme();
                    }}
                />
            ),
            id: 'preferences-toggle-dark-mode-action',
            keywords: ['dark', 'theme', 'mode'],
            name: i18next.t('Dark mode'),
            perform: () => handleOnSwitchTheme(),
            section: i18next.t('Preferences'),
        },
        {
            icon: (
                <Switch
                    checked={preferences.displaySubmenuOnHover}
                    onChange={() => {
                        handleOnToggleMenuPreference();
                    }}
                />
            ),
            id: `preferences-set-submenu-behavior-action`,
            keywords: ['submenu', 'hover', 'desktop'],
            name: i18next.t('Display sidebar submenu on hover'),
            perform: () => handleOnToggleMenuPreference(),
            section: i18next.t('Preferences'),
        },
    ];

    const filterdActions = actions.filter((action) =>
        validateAccessWithPermissions(action.requiredPermissions),
    );

    return filterdActions;
};
