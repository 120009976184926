import React from 'react';

import { push } from 'connected-react-router';
import { useRegisterActions } from 'kbar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as profileActions from '../../../modules/profile/actions';
import * as profileSelectors from '../../../modules/profile/selectors';
import * as profileConfigurationActions from '../../../modules/profileConfiguration/actions';

import { initialActions } from './helpers';

const HandleActions = ({
    preferences,
    redirect,
    saveConfig,
    setPreferences,
    setTheme,
    theme,
}) => {
    const initial = initialActions({
        preferences,
        redirect,
        saveConfig,
        setPreferences,
        setTheme,
        theme,
    });

    useRegisterActions(initial, [initial]);

    return <></>;
};

HandleActions.propTypes = {
    preferences: PropTypes.object,
    redirect: PropTypes.func,
    saveConfig: PropTypes.func,
    setPreferences: PropTypes.func,
    setTheme: PropTypes.func,
    theme: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    preferences: profileSelectors.getPreferences,
    selectedTheme: profileSelectors.getTheme,
});

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
    saveConfig: (config) =>
        dispatch(profileConfigurationActions.saveConfig(config)),
    setTheme: (value) => dispatch(profileActions.setTheme(value)),
    setPreferences: (value) => dispatch(profileActions.setPreferences(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HandleActions);
