import API from '../..';
const ENTITY = 'users';

export const activateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/enable/${id}`, { ...data });
export const changePasswordCompany = (id, data) =>
    API.put(`/api/v1/${ENTITY}/change-password-company/${id}/`, { ...data });
export const changePasswordProfile = (data) =>
    API.post(`/api/v1/${ENTITY}/change-password-profile/`, { ...data });
export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, { ...data });
export const deleteItem = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const filterItems = (str) => API.get(`/api/v1/${ENTITY}/filter/${str}`);
export const getCompanyUsers = () => API.get(`/api/v1/${ENTITY}/company/`);
export const getItems = (branchOffice) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: { ...(branchOffice ? { branch_office: branchOffice } : {}) },
    });
export const getUserById = (id) => API.get(`/api/v1/${ENTITY}/${id}`);
export const getUsersList = (contactId) =>
    API.get(`/api/v1/${ENTITY}-shortlist`, {
        params: { contact: contactId },
    });
export const restartPassword = (id) =>
    API.put(`/api/v1/${ENTITY}/restart-password/${id}`);
export const updateConfigUser = (data) =>
    API.post(`/api/v1/settings/${ENTITY}/`, { ...data });
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
