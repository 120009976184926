import {
    getDemandUnits,
    getEnergyUnits,
    getOthersUnits,
} from 'common/api/v1/unitRates';

import {
    FETCH_UNITS,
    FETCH_UNITS_FAILURE,
    FETCH_UNITS_SUCCESS,
} from '../actionTypes';
import { ratesActions } from '../reducer';

const getEnergyUnitsPromise = () =>
    new Promise((resolve, reject) =>
        getEnergyUnits()
            .then((response) => resolve(response.data.data))
            .catch((error) => reject(error)),
    );

const getDemandUnitsPromise = () =>
    new Promise((resolve, reject) =>
        getDemandUnits()
            .then((response) => resolve(response.data.data))
            .catch((error) => reject(error)),
    );

const getOtherUnitsPromise = () =>
    new Promise((resolve, reject) =>
        getOthersUnits()
            .then((response) => resolve(response.data.data))
            .catch((error) => reject(error)),
    );

export default () => (dispatch) => {
    dispatch(ratesActions[FETCH_UNITS]());
    Promise.all([
        getEnergyUnitsPromise(),
        getDemandUnitsPromise(),
        getOtherUnitsPromise(),
    ])
        .then((response) =>
            dispatch(
                ratesActions[FETCH_UNITS_SUCCESS](
                    response.reduce((acc, current) => [...acc, ...current], []),
                ),
            ),
        )
        .catch((error) => dispatch(ratesActions[FETCH_UNITS_FAILURE](error)));
};
