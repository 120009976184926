import React, { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, Grid, Typography } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import { numberFormat } from 'common/utils/helpers';

import AllianceItem from '../AllianceItem';

const CeideFormStep3 = ({
    alliance,
    control,
    currencyIso,
    currencyLocale,
    infonavitData,
    show,
}) => {
    const { t } = useTranslation();
    const [showInfonavitExtraData, setShowInfonavitExtraData] = useState(false);

    if (!show) return false;

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={9} md={4} lg={3}>
                    <AllianceItem alliance={alliance} />
                </Grid>
            </Grid>
            <Grid container mt={1}>
                <Grid item xs>
                    <ReactHookFormIntlNumberInput
                        control={control}
                        disabled
                        focused
                        fullWidth
                        label={t('Total amount')}
                        name="infonavit_data.total_amount"
                    />
                    <ReactHookFormIntlNumberInput
                        control={control}
                        disabled
                        focused
                        fullWidth
                        label={t('Withholding on INFONAVIT account')}
                        name="infonavit_data.retention_amount"
                    />
                    <ReactHookFormIntlNumberInput
                        control={control}
                        disabled
                        focused
                        fullWidth
                        label={t('Administrative expenses')}
                        name="infonavit_data.administratives_expenses"
                    />
                </Grid>
            </Grid>
            <Grid container mt={1}>
                <Grid item xs textAlign="center">
                    <Typography variant="body2" sx={{ color: '#2f4dff' }}>
                        {t('Amount available for photovoltaic installation')}
                    </Typography>

                    <Typography variant="h4" fontWeight="bold" mt={1}>
                        {numberFormat(infonavitData.available_balance, {
                            currency: currencyIso,
                            locale: currencyLocale,
                            style: 'currency',
                        })}
                    </Typography>
                    <Button
                        endIcon={
                            showInfonavitExtraData ? (
                                <VisibilityOffIcon />
                            ) : (
                                <VisibilityIcon />
                            )
                        }
                        fontWeight="bold"
                        onClick={() =>
                            setShowInfonavitExtraData(!showInfonavitExtraData)
                        }
                        sx={{ mt: 2 }}
                        type="button"
                        variant="cancel"
                    >
                        {t('Verify data')}
                    </Button>
                    <Collapse in={showInfonavitExtraData}>
                        {infonavitData.extra && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: infonavitData.extra.table,
                                }}
                            />
                        )}
                    </Collapse>
                </Grid>
            </Grid>
        </>
    );
};

CeideFormStep3.propTypes = {
    alliance: PropTypes.object,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    infonavitData: PropTypes.object,
    show: PropTypes.bool,
};

export default CeideFormStep3;
