import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    AccordionDetails,
    Box,
    Divider,
    Skeleton,
    Tab,
    Tabs,
    TabPanel,
    Typography,
} from 'sunwise-ui';

import {
    StyledAccordion,
    StyledAccordionSummary,
} from 'common/components/accordions';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { useBreakpoint } from 'common/hooks';

import proposalGeneratorAdvancedConsumptionSettings from '../../proposalGeneratorAdvancedConsumptionSettings';
import proposalGeneratorAdvancedGenerationSettings from '../../proposalGeneratorAdvancedGenerationSettings';
import { getSunHoursInformation } from '../helpers';

const AdvancedSettings = ({
    anualSolarGeneration,
    currencyLocale,
    getPermissionsByCode,
    irradiationValues,
    isLoading,
    isLocked,
    offerConfigGeneration,
    proposalId,
    systemSize,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [selectedTab, setSelectedTab] = useState('generation');

    const { canModify: canModifySolarGeneration } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_SOLAR_GENERATION_PERMISSION,
    );
    const { canModify: canModifySolarGenerationFile } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_SOLAR_GENERATION_UPLOAD_FILE_PERMISSION,
    );

    const { canModify: canModifySolarConsumption } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_SOLAR_CONSUMPTION_PERMISSION,
    );

    const handleChange = (_, newValue) => setSelectedTab(newValue);

    useEffect(() => {
        if (!canModifySolarGeneration) setSelectedTab('consumption');
    }, [canModifySolarGeneration]);

    return (
        <StyledAccordion disableGutters>
            <StyledAccordionSummary
                aria-controls="advanced-content"
                expandIcon={<ExpandMoreIcon />}
                height="70px"
                id="advanced-header"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    <Box display="flex">
                        <SettingsIcon />
                        <Typography variant="body2" ml={1} fontWeight="bold">
                            {t('Advanced', { count: 2 })}
                        </Typography>
                    </Box>

                    {isLoading ? (
                        <Skeleton
                            variant="text"
                            sx={{
                                fontSize: '1rem',
                                width: { xs: 200, lg: 143 },
                            }}
                        />
                    ) : (
                        <Typography variant="body2" ml={4}>
                            {getSunHoursInformation({
                                ...irradiationValues,
                                currencyLocale,
                            })}
                        </Typography>
                    )}
                </Box>
            </StyledAccordionSummary>
            <Divider />
            <AccordionDetails sx={{ p: 0, py: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        variant={
                            ['xs', 'sm', 'md'].includes(breakpoint)
                                ? 'scrollable'
                                : 'standard'
                        }
                    >
                        <Tab
                            id="tab-consumption"
                            key="tab-consumption"
                            label={t('Consumption')}
                            value="consumption"
                        />
                        <Tab
                            id="tab-generation"
                            key="tab-generation"
                            label={t('Generation')}
                            value="generation"
                        />
                    </Tabs>
                </Box>
                <TabPanel
                    key="tab-panel-consumption"
                    selectedTab={selectedTab}
                    value="consumption"
                >
                    <proposalGeneratorAdvancedConsumptionSettings.Container
                        canModify={canModifySolarConsumption}
                        isLoading={isLoading}
                        isLocked={isLocked}
                        proposalId={proposalId}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-panel-generation"
                    selectedTab={selectedTab}
                    value="generation"
                >
                    <proposalGeneratorAdvancedGenerationSettings.Container
                        anualSolarGeneration={anualSolarGeneration}
                        canModifySolarGeneration={canModifySolarGeneration}
                        canModifySolarGenerationFile={
                            canModifySolarGenerationFile
                        }
                        irradiationValues={irradiationValues}
                        isLoading={isLoading}
                        isLocked={isLocked}
                        offerConfigGeneration={offerConfigGeneration}
                        proposalId={proposalId}
                        systemSize={systemSize}
                    />
                </TabPanel>
            </AccordionDetails>
            <Divider />
        </StyledAccordion>
    );
};

AdvancedSettings.propTypes = {
    anualSolarGeneration: PropTypes.number,
    currencyLocale: PropTypes.string,
    getPermissionsByCode: PropTypes.func,
    irradiationValues: PropTypes.object,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    offerConfigGeneration: PropTypes.object,
    proposalId: PropTypes.string,
    systemSize: PropTypes.number,
};

export default withPermissions([
    PERMISSION_LIST.PROPOSAL_SOLAR_CONSUMPTION_PERMISSION,
    PERMISSION_LIST.PROPOSAL_SOLAR_GENERATION_PERMISSION,
    PERMISSION_LIST.PROPOSAL_SOLAR_GENERATION_UPLOAD_FILE_PERMISSION,
])(AdvancedSettings);
