import API from '../..';
const ENTITY = 'companies-currencies';

export const activateItem = (id) => API.put(`/api/v1/active/${ENTITY}/${id}`);
export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, { ...data });
export const deleteItem = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const filterItems = (searchText) =>
    API.get(`/api/v1/${ENTITY}/${searchText}`);
export const getItems = () => API.get(`/api/v1/${ENTITY}/`);
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
export const updateOrderItems = (values) =>
    API.put(`/api/v1/update-order/${ENTITY}`, { ...values });
