import API from '../..';
const ENTITY = 'design';

export const checkSegment = (data) =>
    API.post(`/api/v1/${ENTITY}/compute-solar-modules/`, { ...data });
export const createParentFieldSegments = (commercialOfferId, data = {}) =>
    API.post(`/api/v1/${ENTITY}/${commercialOfferId}/`, { ...data });
export const deleteParentFieldSegments = (commercialOfferId) =>
    API.delete(`/api/v1/${ENTITY}/${commercialOfferId}/`);
export const editParentFieldSegments = (commercialOfferId, data = {}) =>
    API.put(`/api/v1/${ENTITY}/${commercialOfferId}/`, { ...data });

export const saveShaderSetup = (fieldSegmentUUID, data) =>
    API.put(`/api/v1/parent/${fieldSegmentUUID}/`, { ...data });
