import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormInputDatePicker,
} from 'common/components/form/bootstrap';

import AllianceItem from '../AllianceItem';

const CeideFormStep2 = ({ alliance, control, productSelected, show }) => {
    const { t } = useTranslation();

    if (!show) return false;

    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={9} md={4} lg={3}>
                    <AllianceItem alliance={alliance} />
                </Grid>
            </Grid>
            <Grid container mt={2}>
                <Grid item xs>
                    <Typography variant="body2" fontWeight="bold">
                        {productSelected.name}
                    </Typography>

                    <Typography variant="caption">
                        {productSelected.description}
                    </Typography>
                    <Typography variant="caption" mt={2}>
                        {t('Enter your data')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container mt={1}>
                <Grid item xs>
                    <ReactHookFormInput
                        control={control}
                        fullWidth
                        label={t('Social security number')}
                        name="infonavit_data.nss"
                    />
                    <ReactHookFormInputDatePicker
                        control={control}
                        label={t('Date of birth')}
                        maxDate={new Date()}
                        name="infonavit_data.birthday"
                    />
                </Grid>
            </Grid>
        </>
    );
};

CeideFormStep2.propTypes = {
    alliance: PropTypes.object,
    control: PropTypes.object,
    productSelected: PropTypes.object,
    show: PropTypes.bool,
};

export default CeideFormStep2;
