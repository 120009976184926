import API from '../..';
const ENTITY = 'taxes-offer';

export const getItems = (offerId) => API.get(`/api/v2/${ENTITY}/${offerId}`);
export const deleteItem = (id, offerId) =>
    API.delete(`/api/v2/taxes-offer/${offerId}?id=${id}`);
export const updateItem = (values, offerId) =>
    API.put(`/api/v2/${ENTITY}/${offerId}`, values, {
        headers: { 'Content-Type': 'application/json' },
    });
