import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Chip, Typography } from 'sunwise-ui';

import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import * as credentialIntegrationsSelectors from '../../credentialIntegrations/selectors';
import { FILTERS_KEYS, FILTERS_TO_DISPLAY, STATUS_CONFIG } from '../constants';
import { getDateRangeLabel, getFilterNameFromOptions } from '../helpers';
import * as selectors from '../selectors';

const AppliedFilters = ({
    agents,
    contacts,
    filtersValues,
    politicalDivisions,
    projectStatuses,
    projects,
    providers,
    rates,
}) => {
    const { t } = useTranslation();
    const dateFormat = getDateFormatByLocale();

    const getFilterValue = (key, value) => {
        switch (key) {
            case FILTERS_KEYS.AGENT:
                return getFilterNameFromOptions(value, agents);
            case FILTERS_KEYS.CONTACT_ID:
                return getFilterNameFromOptions(value, contacts);
            case FILTERS_KEYS.POLITICAL_DIVISION:
                return getFilterNameFromOptions(value, politicalDivisions);
            case FILTERS_KEYS.PROJECT_ID:
                return getFilterNameFromOptions(value, projects);
            case FILTERS_KEYS.PROVIDER:
                return getFilterNameFromOptions(value, providers);
            case FILTERS_KEYS.PROJECT_STATUS:
                return getFilterNameFromOptions(value, projectStatuses);
            case FILTERS_KEYS.RATE_ID:
                return getFilterNameFromOptions(value, rates);
            case FILTERS_KEYS.STATUS:
                return t(STATUS_CONFIG[value]?.label) || '';
            default:
                return value;
        }
    };

    const dateChipLabel = getDateRangeLabel(
        filtersValues?.[FILTERS_KEYS.INITIAL_DATE],
        filtersValues?.[FILTERS_KEYS.FINAL_DATE],
        dateFormat,
    );

    return (
        <Box display="flex" alignItems="center" flexWrap="wrap" gap={1} my={1}>
            <Typography variant="subtitle1">
                {t('System', { count: 2 })}:
            </Typography>

            {dateChipLabel && <Chip label={dateChipLabel} variant="outlined" />}

            {FILTERS_TO_DISPLAY.map((key) => {
                const value = getFilterValue(key, filtersValues[key]);
                if (!value) return null;
                return <Chip key={key} label={value} variant="outlined" />;
            })}
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    agents: selectors.getAgentsForSelect,
    contacts: selectors.getContactsForSelect,
    politicalDivisions: selectors.getPoliticalDivisionForSelect,
    projectStatuses: selectors.getProjectStatusesForSelect,
    projects: selectors.getProjectsForSelect,
    providers: credentialIntegrationsSelectors.getProvidersForSelect2,
    rates: selectors.getRatesForSelect,
});

AppliedFilters.propTypes = {
    agents: PropTypes.array,
    contacts: PropTypes.array,
    filtersValues: PropTypes.object,
    politicalDivisions: PropTypes.array,
    projectStatuses: PropTypes.array,
    projects: PropTypes.array,
    providers: PropTypes.array,
    rates: PropTypes.array,
};

export default connect(mapStateToProps)(AppliedFilters);
