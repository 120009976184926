import API from '../..';
import openAPI from '../../openAPI';

const ENTITY = 'smart-documents';

export const createSmartDocument = (data) =>
    API.post(`/api/v1/${ENTITY}/`, { ...data });
export const deleteSmartDocument = (id) =>
    API.delete(`/api/v1/${ENTITY}/${id}`);
export const downloadSmartDocumentCSV = (id) =>
    API.get(`/api/v1/${ENTITY}-end-csv/${id}`);
export const duplicateSmartDocument = (id, data) =>
    API.put(`/api/v1/duplicate-${ENTITY}/${id}`, { ...data });
export const finishSmartDocumentProposal = (id) =>
    API.get(`/api/v1/${ENTITY}-end/${id}`);
export const generatePreview = (id) =>
    API.put(`/api/v1/${ENTITY}/${id}/regenerate/`);
export const getSmartDocument = (id) => API.get(`/api/v1/${ENTITY}/${id}`);
export const getSmartDocumentEnded = (id) =>
    API.get(`/api/v1/${ENTITY}-ended/${id}`);
export const getSmartDocumentEndedWithoutToken = (id) =>
    openAPI.get(`/api/v1/sunpal-${ENTITY}-ended/${id}`);
export const updateSmartDocument = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
export const updateSmartDocumentEnded = (id, data) =>
    API.put(`/api/v1/${ENTITY}-ended/${id}`, data);
