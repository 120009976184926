import API from '../..';
const ENTITY = 'customers-energies-consumptions';

export const create = (data, projectUUID) =>
    API.post(
        `/api/v2/${ENTITY}/${projectUUID}`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } },
    );
export const getConsumptionEnergy = (projectUUID) =>
    API.get(`/api/v2/${ENTITY}/${projectUUID}`);
export const update = (data, projectUUID) =>
    API.put(
        `/api/v2/${ENTITY}/${projectUUID}`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } },
    );
export const uploadCsv = (data) =>
    API.post(`/api/v2/${ENTITY}-csv-cleaner/`, { ...data });
