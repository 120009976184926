import {
    RESET_FORM_CEIDE,
    RESET_FORM_CI_BANCO,
    RESET_FORM_RED_GIRASOL,
} from '../actionTypes';
import { financingAndInsuranceActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(financingAndInsuranceActions[RESET_FORM_CEIDE]());
    dispatch(financingAndInsuranceActions[RESET_FORM_CI_BANCO]());
    dispatch(financingAndInsuranceActions[RESET_FORM_RED_GIRASOL]());
};
