import API from '../..';
const ENTITY = 'funnel';

export const downloadProjectsFile = (downloadFilter) =>
    API.get(`/api/v2/${ENTITY}/export/xls/?${downloadFilter}`, {
        headers: { 'Content-Type': 'application/ms-excel' },
    });
export const getFunnelProjects = ({ perPage = 7, filterQuery }) =>
    API.get(
        `/api/v2/${ENTITY}-projects/?per_page=${perPage}${
            filterQuery ? '&' + filterQuery : ''
        }`,
    );
export const getListProjects = ({ perPage = 7, filterQuery }) =>
    API.get(
        `/api/v2/${ENTITY}/listview/?per_page=${perPage}${
            filterQuery ? '&' + filterQuery : ''
        }`,
    );
export const getNewPageProjects = ({
    status,
    nextProject,
    perPage = 7,
    filterQuery,
}) =>
    API.get(
        `/api/v2/projects/?status=${status}&next=${nextProject}&per_page=${perPage}${
            filterQuery ? '&' + filterQuery : ''
        }`,
    );
export const getNextPageListProjects = ({ page, perPage = 7, filterQuery }) =>
    API.get(
        `/api/v2/${ENTITY}/listview/?page=${page}&per_page=${perPage}${
            filterQuery ? '&' + filterQuery : ''
        }`,
    );
export const updateStatusOrder = (data) =>
    API.put(
        `/api/v2/${ENTITY}/update/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } },
    );
