import React, { useContext, useEffect, useState } from 'react';

import { Map } from 'google-maps-react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
    SEGMENT_COLOR,
    SEGMENT_STROKE_COLOR,
    SOLAR_MODULE_COLOR,
    SOLAR_MODULE_STROKE_COLOR,
} from 'common/components/maps/constants';
import { DEFAULT_LAT, DEFAULT_LNG } from 'common/constants';
import { GeneralContext } from 'common/utils/contexts';
import { arraytoDictionary } from 'common/utils/helpers';

import { DELTA, mapStyles } from './constants';
import { mapValueUseEffect } from './helpers';
import WrapperMap from './WrapperMap';

const SegmentsMap = ({
    center = { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
    clickable = true,
    fullscreenControl = false,
    handleCenterChanged,
    handleZoomChanged,
    height,
    initCenter,
    projectLocation,
    scaleControl = true,
    segmentFillColor = SEGMENT_COLOR,
    segmentStrokeColor = SEGMENT_STROKE_COLOR,
    segments = [],
    showLabels = true,
    showProjectMarker = true,
    solarModuleFillColor = SOLAR_MODULE_COLOR,
    solarModuleStrokeColor = SOLAR_MODULE_STROKE_COLOR,
    width,
    zoom = 18,
    zoomControl = false,
}) => {
    const { t } = useTranslation();
    const { google } = useContext(GeneralContext);
    const [mapValue, setMapValue] = useState(null);
    const [shapes, setShapes] = useState({});
    const initialCenter = {
        lat: get(initCenter, 'latitude', center.lat),
        lng: get(initCenter, 'longitude', center.lng),
    };
    const projectMarkerCenter = {
        lat: get(projectLocation, 'latitude', center.lat),
        lng: get(projectLocation, 'longitude', center.lng),
    };

    useEffect(() => {
        if (mapValue)
            mapValue.setOptions({
                fullscreenControl: false,
                gestureHandling: 'none',
                panControl: false,
                restriction: {
                    latLngBounds: {
                        east: center.lng + DELTA,
                        north: center.lat + DELTA,
                        south: center.lat - DELTA,
                        west: center.lng - DELTA,
                    },
                    strictBounds: true,
                },
                scaleControl: false,
                scrollwheel: false,
                zoomControl: false,
            });
    }, [mapValue]);

    useEffect(() => {
        if (mapValue) mapValue.setOptions({ zoomControl });
    }, [zoomControl]);

    useEffect(() => {
        if (mapValue) {
            mapValue.setOptions({ scaleControl });
        }
    }, [scaleControl]);

    useEffect(() => {
        Object.keys(shapes).forEach((key) => {
            shapes[key].setLabelsVisibility(showLabels);
        });
    }, [showLabels]);

    useEffect(() => {
        if (mapValue) {
            if (showProjectMarker) projectMarker.setMap(mapValue);
            else projectMarker.setMap(null);
        }
    }, [showProjectMarker]);

    useEffect(() => {
        Object.keys(shapes).forEach((key) => {
            shapes[key].setOptions({
                fillColor: segmentFillColor,
            });
        });
    }, [segmentFillColor]);

    useEffect(() => {
        Object.keys(shapes).forEach((key) => {
            shapes[key].setOptions({
                strokeColor: segmentStrokeColor,
            });
        });
    }, [segmentStrokeColor]);

    useEffect(() => {
        Object.keys(shapes).forEach((key) => {
            shapes[key].setSolarModulesFillColor(solarModuleFillColor);
        });
    }, [solarModuleFillColor]);

    useEffect(() => {
        Object.keys(shapes).forEach((key) => {
            shapes[key].setSolarModulesStrokeColor(solarModuleStrokeColor);
        });
    }, [solarModuleStrokeColor]);

    const [projectMarker] = useState(
        new google.maps.Marker({
            name: t('Location'),
            position: new google.maps.LatLng(
                projectMarkerCenter.lat,
                projectMarkerCenter.lng,
            ),
            title: get(projectLocation, 'address', ''),
        }),
    );

    useEffect(
        mapValueUseEffect({
            google,
            handleCenterChanged,
            handleZoomChanged,
            mapValue,
            projectMarker,
            scaleControl,
            segmentsDictionary: arraytoDictionary(segments, 'id'),
            setShapes,
            showLabels,
            showProjectMarker,
            solarModuleFillColor,
            solarModuleStrokeColor,
            zoomControl,
        }),
        [mapValue],
    );

    return (
        <WrapperMap
            className={!clickable && 'unclickable'}
            height={height}
            width={width}
        >
            <Map
                fullscreenControl={fullscreenControl}
                google={google}
                initialCenter={initialCenter}
                mapType="satellite"
                mapTypeControl={false}
                onReady={(p, map) => setMapValue(map)}
                rotateControl={false}
                scaleControl={scaleControl}
                streetViewControl={false}
                styles={mapStyles}
                zoom={zoom}
            />
        </WrapperMap>
    );
};

SegmentsMap.propTypes = {
    center: PropTypes.object,
    clickable: PropTypes.bool,
    fullscreenControl: PropTypes.bool,
    handleCenterChanged: PropTypes.func,
    handleZoomChanged: PropTypes.func,
    height: PropTypes.string,
    initCenter: PropTypes.object,
    projectLocation: PropTypes.object,
    scaleControl: PropTypes.bool,
    segmentFillColor: PropTypes.string,
    segments: PropTypes.array,
    segmentStrokeColor: PropTypes.string,
    showLabels: PropTypes.bool,
    showProjectMarker: PropTypes.bool,
    solarModuleFillColor: PropTypes.string,
    solarModuleStrokeColor: PropTypes.string,
    width: PropTypes.string,
    zoom: PropTypes.number,
    zoomControl: PropTypes.bool,
};

export default SegmentsMap;
