import React from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useFieldArray, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button } from 'sunwise-ui';

import { getCountryCurrencySymbol } from 'common/utils/helpers/session';

import { addConceptInput } from '../../../helpers';

import Concept from './Concept';

const FieldsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const FieldArrayConcepts = ({
    control,
    disableActions = false,
    getValues,
    isConceptsOtherChargesTaxes,
    isContractedDemandConcept,
    isEnergyConcept,
    isHourlyRate,
    linkedConceptName,
    linkedConceptParentName,
    name,
    periodFieldName,
    setValue,
    showMode = false,
    topValueFieldEnabled,
    unitItems,
}) => {
    const { t } = useTranslation();
    const { append, fields, insert } = useFieldArray({ control, name });
    const { errors } = useFormState({ control, name });

    const countryCurrencySymbol = getCountryCurrencySymbol();

    if (fields.length === 0 && showMode && isEnergyConcept)
        return (
            <Alert severity="warning">
                {t('You must add at least one charge')}
            </Alert>
        );

    const isHourlyLinkedConcept = linkedConceptParentName && isHourlyRate;
    const isHourlyField =
        (isHourlyRate && (isEnergyConcept || isContractedDemandConcept)) ||
        isHourlyLinkedConcept;
    const _disabled = showMode || (disableActions && isHourlyField);
    const showAlert = isEnergyConcept && fields.length === 0;

    return (
        <FieldsContainer>
            {showAlert && (
                <Alert severity="warning">
                    {t('You must add at least one charge')}
                </Alert>
            )}

            {fields.map((field, index) => (
                <Concept
                    control={control}
                    countryCurrencySymbol={countryCurrencySymbol}
                    disabled={_disabled}
                    getValues={getValues}
                    index={index}
                    isEnergyConcept={isEnergyConcept}
                    isHourlyField={isHourlyField}
                    isHourlyLinkedConcept={isHourlyLinkedConcept}
                    isHourlyRate={isHourlyRate}
                    isLastConcept={index === fields.length - 1}
                    key={field.id}
                    linkedConceptName={linkedConceptName}
                    name={name}
                    periodFieldName={periodFieldName}
                    setValue={setValue}
                    showMode={showMode}
                    topValueFieldEnabled={topValueFieldEnabled}
                    unitItems={unitItems}
                />
            ))}

            {!_disabled && (
                <>
                    {isHourlyLinkedConcept && fields?.length ? (
                        <Button
                            color="error"
                            onClick={() => setValue(name, [])}
                            type="button"
                            variant="outlined"
                        >
                            {t('Delete')}
                        </Button>
                    ) : (
                        <Button
                            disabled={
                                (!isEmpty(get(errors, name)) &&
                                    fields?.length > 0) ||
                                (isHourlyField && fields?.length >= 24)
                            }
                            onClick={() =>
                                addConceptInput({
                                    append,
                                    getValues,
                                    index: fields.length - 1,
                                    insert,
                                    isConceptsOtherChargesTaxes,
                                    isEnergyConcept,
                                    isHourlyRate,
                                    linkedConceptName,
                                    linkedConceptParentName,
                                    name,
                                    setValue,
                                    topValueFieldEnabled,
                                    unitItems,
                                    values: getValues(name),
                                })
                            }
                            type="button"
                        >
                            {t('Add')}
                        </Button>
                    )}
                </>
            )}
        </FieldsContainer>
    );
};

FieldArrayConcepts.propTypes = {
    control: PropTypes.object,
    disableActions: PropTypes.bool,
    getValues: PropTypes.func,
    isConceptsOtherChargesTaxes: PropTypes.bool,
    isContractedDemandConcept: PropTypes.bool,
    isEnergyConcept: PropTypes.bool,
    isHourlyRate: PropTypes.bool,
    linkedConceptName: PropTypes.string,
    linkedConceptParentName: PropTypes.string,
    name: PropTypes.string,
    periodFieldName: PropTypes.string,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    topValueFieldEnabled: PropTypes.bool,
    unitItems: PropTypes.array,
};

export default FieldArrayConcepts;
